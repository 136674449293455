export class Lang {

    public static defaultLocale: string = 'en';

    public static t: typeof Lang.translations = null;

    public static numberFormats = {
        usd: {
            style: 'currency',
            currency: 'USD',
        },
        eur: {
            style: 'currency',
            currency: 'EUR',
        },
        eurNoCents: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        pct: {
            style: 'percent',
        },
    };

    public static dateFormats = {
        date: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        dateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        minSec: {
            minute: 'numeric',
            second: 'numeric',
        },
    };

    /**
     * Locales label in its own language (should not be translated!)
     */
    public static localesNative = {
        en: 'English',
        de: 'Deutsch',
        nl: 'Nederlands',
        pt: 'Português',
        it: 'Italiano',
        es: 'Español',
        fr: 'Français',
        pl: 'Polskie',
        ro: 'Română',
        bg: 'Български',
        cs: 'Čeština',
        da: 'Dansk',
        el: 'Ελληνικά',
        et: 'Eesti',
        fi: 'Soome',
        hu: 'Magyar',
        // hr: 'Hrvatski',  // NOTE: Not supported by Deepl!
        lv: 'Latvietis',
        lt: 'Lietuvis',
        sk: 'Slovensky',
        sl: 'Slovenščina',
        sv: 'Svenska',
    };

    /**
     * Active countries in their own language (should not be translated!)
     */
    public static countriesNative = {
        al: 'Shqipëria',
        ad: 'Andorra',
        at: 'Österreich',
        by: 'Biełaruś',
        be: 'België',
        ba: 'Босна и Херцеговина',
        bg: 'България',
        hr: 'Hrvatska',
        cy: 'Κύπρος',
        cz: 'Česká republika',
        dk: 'Danmark',
        ee: 'Eesti Vabariik',
        fi: 'Suomi',
        fr: 'France',
        ge: 'საქართველო',
        de: 'Deutschland',
        gr: 'Ελλάδα',
        hu: 'Magyarország',
        is: 'Ísland',
        ie: 'Éire',
        it: 'Italia',
        xk: 'Република Косово',
        lv: 'Latvija',
        li: 'Liechtenstein',
        lt: 'Lietuva',
        lu: 'Lëtzebuerg',
        mt: 'Malta',
        md: 'Republica Moldova',
        me: 'Црна Гора',
        nl: 'Nederland',
        mk: 'Република Северна Македониј',
        no: 'Norge',
        pl: 'Polska',
        pt: 'Portugal',
        ro: 'România',
        sm: 'San Marino',
        rs: 'Србија',
        sk: 'Slovensko',
        si: 'Slovenija',
        es: 'España',
        se: 'Sverige',
        ch: 'Schweiz',
        tr: 'Türkiye',
        ua: 'Україна',
        uk: 'United Kingdom',
        us: 'United States of America',
        va: 'Città del Vaticano',
    };

    public static phoneCountryCodes = {
        '+355': 'AL',
        '+376': 'AD',
        '+43': 'AU',
        '+375': 'BY',
        '+32': 'BE',
        '+387': 'BA',
        '+359': 'BG',
        '+385': 'HR',
        '+357': 'CY',
        '+420': 'CZ',
        '+45': 'DK',
        '+372': 'EE',
        '+358': 'FI',
        '+33': 'FR',
        '+995': 'GE',
        '+49': 'DE',
        '+30': 'GR',
        '+36': 'HU',
        '+354': 'IS',
        '+353': 'IE',
        '+39': 'IT',
        '+383': 'XK',
        '+371': 'LV',
        '+423': 'LI',
        '+370': 'LT',
        '+352': 'LU',
        '+356': 'MT',
        '+373': 'MD',
        '+382': 'ME',
        '+31': 'NL',
        '+389': 'MK',
        '+47': 'NO',
        '+48': 'PL',
        '+351': 'PT',
        '+40': 'RO',
        '+378': 'SM',
        '+381': 'RS',
        '+421': 'SK',
        '+386': 'SI',
        '+34': 'ES',
        '+46': 'SE',
        '+41': 'CH',
        '+90': 'TR',
        '+380': 'UA',
        '+44': 'UK',
        '+1': 'USA',
        '+379': 'VA',
    };

    public static translations = {
        locales: {
            en: 'English',
            de: 'German',
            nl: 'Dutch',
            pt: 'Portuguese',
            it: 'Italian',
            es: 'Spanish',
            fr: 'French',
            pl: 'Polish',
            ro: 'Romanian',
            bg: 'Bulgarian',
            cs: 'Czech',
            da: 'Danish',
            el: 'Greek',
            et: 'Estonian',
            fi: 'Finnish',
            hu: 'Hungarian',
            hr: 'Croatian',
            lv: 'Latvian',
            lt: 'Lithuanian',
            sk: 'Slovak',
            sl: 'Slovenian',
            sv: 'Swedish',
        },
        errors: {
            title: 'Oops!',
            _404: {
                title: 'It seems like we weren\'t able to thread the needle.',
                description: 'Go back to dashboard or contact our team for support.',
            },
            _400: 'An unknown error has occurred!',
            unknown: 'An unknown error has occured!<br />{message}', // {message}
            _401: 'Your session has expired!<br />Please sign in again.',
            accessForbidden: 'You don\'t have access to the requested page!',
            callForbidden: 'You don\'t have permission for the requested call!',
            fileSizeExceded: 'Something went wrong:<br />{message}', // {message}
            invalidArguments: 'Something went wrong:<br />{message}', // {message}
            invalidFileType: 'The file cannot be of type {type}.<br />The file must be of type: {types}.', // {message}
            notImplemented: 'This functionality is not implemented yet!',
            requiredField: 'Please fill out this field',
            formError: 'Please correct the marked fields!',
            invalidJsonField: 'Invalid JSON!',
            invalidEmail: 'Please enter a valid e-mail address!',
            invalidUrl: 'Please enter a valid URL starting with either "http://", "https://" or "//"',
            invalidPassword: 'Please enter a valid password!',
            invalidValue: 'Please enter a valid value!',
            minLength: 'This field must be {min} characters or more!', // {min}
            maxLength: 'This field must be {max} characters or less!', // {max}
            minLengthList: 'Select at least {min}!', // {min}
            maxLengthList: 'Select at most {max}!', // {max}
            min: 'This value can\'t be less than {min}!', // {min}
            max: 'This value can\'t be more than {max}!', // {max}
            range: 'This value must be between {min} and {max}', // {min}, {max}
            minDate: 'This date must be {minDate} or later!', // {minDate}
            maxDate: 'This date must be {maxDate} or earlier!', // {maxDate}
            rangeDate: 'This date must be between {minDate} and {maxDate}!', // {minDate}, {maxDate}
            in: 'This value must be any of the following: {list}', // {list}
            minImageDimensions: 'Image should be at least {varMinWidth} x {varMinHeight}',
            fabricPercentage: 'Fabric percentages can\'t add up to more than 100%',
        },
        components: {
            createProjectSupportBlock: {
                title: 'Cannot find what you are looking for?',
                description: 'Create a custom request and send it to our network',
                gettingStarted: {
                    caption: 'Getting started',
                    rfq: 'Learn more about RFQs',
                    techpack: 'Learn more about techpacks',
                },
            },
            customerSupportBlock: {
                title: 'Need customer support?',
                description: 'Our customer support team is ready to assist you!',
            },
            locationPicker: {
                selectBtnLabel: 'Select location',
                selectOtherBtnLabel: 'Select other location',
                noSelection: 'No location selected.',
            },
            pagination: {
                itemsPerPage: 'Items per page',   // *tc {count}
                itemsPerPageAll: 'All', // The label for the per page 'All' option
                label: '{varStartItem} - {varEndItem} of {varTotalItems}',    // {varCurrentPage}, {varTotalPages}, {varStartItem}, {varEndItem}, {varTotalItems}
            },
            pricingCard: {
                days: '{count} days|{count} day|{count} days',
                weeks: '{count} weeks|{count} week|{count} weeks',
                yearly: '/year',
                monthly: '/month',
                oldPrice: 'was &euro;{price}/{period}',
                trialPeriod: '{time} trial',
                billedAnnually: 'Billed annually',
                startingFrom: 'Starting from',
            },
            selectPicker: {
                placeholder: 'Select',
                selectMore: '+ {step} more', // {step}
                selectLess: 'Show less',
                noResults: 'No results found.',
                clearAll: 'Clear',
                selectAll: 'Select all',
                multiselectLabel: 'no items selected|{count} item selected|{count} items selected', // *tc {count}
            },
            textClamp: {
                showMore: 'Show more',
                showLess: 'Show less',
            },
        },
        dateTimeSince: [
            {past: 'just now', future: 'just now',},
            {past: 'a second ago|{count} seconds ago', future: 'in a second|in {count} seconds',},
            {past: 'a minute ago|{count} minutes ago', future: 'in a minute|in {count} minutes',},
            {past: 'an hour ago|{count} hours ago', future: 'in an hour|in {count} hours',},
            {past: 'yesterday|{count} days ago', future: 'tomorrow|in {count} days',},
            {past: 'last week|{count} weeks ago', future: 'next week|in {count} weeks',},
            {past: 'last month|{count} months ago', future: 'next month|in {count} months',},
            {past: 'last year|{count} years ago', future: 'next year|in {count} years',},
            {past: 'last century|{count} centuries ago', future: 'next century|in {count} centuries',},
            {past: 'last millennium|{count} millennia ago', future: 'next millennium|in {count} millennia',},
        ],
        app: {
            title: 'Manufy',
            docTitle: 'Manufy - #docTitle',
            labels: {
                complete: 'Complete',
                register: 'Register',
                close: 'Close',
                back: 'Back',
                delete: 'Delete',
                restore: 'Restore',
                cancel: 'Cancel',
                decline: 'Decline',
                accept: 'Accept',
                save: 'Save',
                send: 'Send',
                sendingTo: 'Sending to',
                update: 'Update',
                edit: 'Edit',
                discard: 'Discard',
                clear: 'Clear',
                add: 'Add',
                yes: 'Yes',
                no: 'No',
                ok: 'OK',
                other: 'Other',
                prev: 'Back',
                next: 'Next',
                submit: 'Submit',
                done: 'Done',
                reset: 'Reset',
                download: 'Download',
                logout: 'Sign out',
                login: 'Sign in',
                signUp: 'Sign up',
                chat: 'Chat',
                toChat: 'To chat',
                success: 'Success',
                editProfile: 'Edit Profile',
                editProduct: 'Edit Product',
                viewProfile: 'View Profile',
                viewProduct: 'View Product',
                loadMore: 'Load more',
                showMore: 'Show more',
                showLess: 'Show less',
                viewInAdminPanel: 'View Admin Page',
                inviteToProject: 'Invite to project',
                sendInvite: 'Send Invite',
                sendInvites: 'Send Invites',
                messages: 'Messages',
                newMessagesCount: '{count} new messages|{count} new message|{count} new messages',
                newMessages: 'New messages',
                newProject: 'New Project',
                newProduct: 'New Product',
                newRequest: 'New Request',
                addProduct: 'Add Product',
                addDocument: 'Add Document',
                deleteProduct: 'Delete Product',
                view: 'View',
                viewOnline: 'View Online',
                viewAll: 'View All',
                seeAll: 'See All',
                hideAll: 'Hide All',
                learnMore: 'Learn More',
                request: 'Request',
                changeAvatar: 'Change Avatar',
                backHome: 'Back Home',
                startProject: 'Start project',
                myDashboard: 'My Dashboard',
                getStarted: 'Get Started',
                joinNow: 'Join Now',
                viewRequest: 'View Request',
                cancelRequest: 'Cancel request',
                createNew: 'Create new',
                comingSoon: 'Coming Soon',
                uploadFile: '<strong class="text-green-400">Upload a file</strong> or drag and drop',
                uploadFiles: '<strong class="text-green-400">Upload files</strong> or drag and drop',
                uploadSingleFile: 'Please upload 1 file',
                uploadedFiles: 'Uploaded file(s)',
                removeAll: 'Remove all',
                giveCredits: 'Give Credits',
                allCountriesEurope: 'Europe',
                free: 'FREE',
                tbd: 'TBD',
                toBeDetermined: 'To be determined',
                sameAddressAsDelivery: 'Same as delivery address',
                makePayment: 'Make Secure Payment',
                uploadInvoice: 'Upload Invoice',
                co2Compensation: 'CO2 Compensation',
                readMore: 'Read more',
                moreCount: '+{count} more',
                newPurchaseOrder: 'New purchase order',
                newSampleOrder: 'New sample order',
                buyer: 'Brand',
                seller: 'Manufacturer',
                seeAllSellers: 'All manufacturers',
                seeAllProducts: 'All products',
                showAllProducts: 'Show all products',
                seeProjects: 'See projects',
                hideProducts: 'Hide products',
                orders: 'Orders',
                samples: 'Samples',
                notAvailable: 'N/A',
                noResults: 'No results found',
                noProjectsFound: 'No projects found',
                noProjects: 'No projects',
                numberOfSelected: '{count} of {max} selected',
                numberOfResults: '{count} Results|{count} Result|{count} Results',
                numberOfReviews: '{count} reviews|{count} review|{count} reviews',
                numberOfOrders: '{count} orders|{count} order|{count} orders',
                samplesMade: 'Samples',
                ordersShipped: 'Orders',
                manufyPartner: 'Manufy Partner',
                status: 'Status',
                completeVerification: 'Complete verification',
                shareProfile: 'Share Profile',
                reportCompany: 'Report Company',
                joinVideoCall: 'Join meeting',
                filters: 'Filters',
                archive: 'Archive',
                archived: 'Archived',
                active: 'Active',
                unarchive: 'Unarchive',
                labelAs: 'Label as',
                review: 'Review',
                payHere: 'Pay Here',
                apply: 'Apply',
                needsAction: 'Needs Action',
                manufyValues: 'Manufy Values',
                viewDocument: 'View document',
                preview: 'Preview',
                sendInquiry: 'Send inquiry',
                requestSample: 'Request sample',
                or: 'or',
                select: 'Select',
                selected: 'Selected',
                contactCustomerSupport: 'Contact customer support',
                mute: 'Mute',
                unmute: 'Unmute',
                openProject: 'Open project',
                subscriptionTier: 'Subscription',
                notVerified: 'User not verified',
                verifiedUser: 'Verified user',
                skip: 'Skip',
                searchPlaceholder: 'Search...',
                searchLabel: 'Search',
                recommended: 'Recommended',
                startTrial: 'Start trial',
                needHelp: 'Need help?',
                retryPayment: 'Retry payment',
                searchingFor: 'Searching for',
                availability: 'Availability',
                feedback: 'Feedback',
            },
            buttonLabels: {
                accountComplete: 'Complete account',
                add: 'Add',
                addBrand: 'Add another brand',
                addFabric: 'Add fabric',
                addProduct: 'Add Product',
                addRequest: 'Add Request',
                back: 'Back',
                bookCall: 'Book A Call',
                browse: 'Browse network',
                cancel: 'Cancel',
                contactUs: 'Contact us',
                confirmTransaction: 'Confirm transaction',
                customerSupport: 'Customer support',
                clear: 'Clear',
                createAccount: 'Create account',
                delete: 'Delete',
                deleteProduct: 'Delete Product',
                edit: 'Edit',
                forgotPassword: 'Forgot password?',
                leaveReview: 'Leave a review',
                loadMore: 'Load more',
                openAnalysis: 'Open analysis',
                offerMake: 'Make offer',
                offerSend: 'Send offer',
                offerSent: 'Offer sent',
                projectCreate: 'Create new project',
                publish: 'Publish',
                next: 'Next',
                readMore: 'Read more',
                resendVerificationEmail: 'Resend verification email',
                reset: 'Reset',
                resetPassword: 'Reset password',
                save: 'Save',
                scheduleACall: 'Schedule a call',
                sendFeedback: 'Send feedback',
                sendLink: 'Send link',
                sendMessage: 'Send message',
                showMore: 'Show more',
                signIn: 'Sign in',
                skip: 'Skip',
                startNow: 'Start Now',
                startPayment: 'Start Payment',
                startTrial: 'Start trial',
                submit: 'Submit',
                toDashboard: 'To Dashboard',
                toQuotationWall: 'To Quotation Wall',
                update: 'Update',
                unpublish: 'Unpublish',
                upgradeSubscription: 'Upgrade subscription to add more',
                updateAvailabilityStatus: 'Update status',
                viewProduct: 'View product',
                view: 'View',
            },
            externalCompanyType: {
                buyer: 'Fashion Brand',
                seller: 'Supply Chain Partner',
                service_provider: 'Circularity Solution',
            },
            menus: {
                home: 'Home',
                projects: 'Projects',
                myProduction: 'Sourcing',
                products: 'Products',
                sampleOrders: 'Sample Orders',
                purchaseOrders: 'Orders',
                productOffers: 'Offers',
                reviews: 'Reviews',
                sellers: 'Sellers',
                buyers: 'Buyers',
                onboarding: 'Onboarding',
                users: 'Users',
                catalogue: 'Catalogue',
                invoices: 'Invoices',
                transactions: 'Transactions',
                admins: 'Admins',
                translations: 'Translations',
                help: 'Help',
                interactions: 'Interactions',
                administration: 'Administration',
                inquiries: 'Inquiries',
                manufyValueApplications: 'Value Applications',
                publicProfile: 'Public profile',
                contactInfo: 'Contact info',
                legalInfo: 'Legal info',
                team: 'Team',
                subscription: 'Subscription',
                explore: 'Explore',
                rfqReviews: 'Project Reviews',
                media: 'Media',
                services: 'Services',
                fabrics: 'Fabrics',
            },
            profileMenu: {
                creditsAvailable: 'Credits available',
                buyMoreCredits: 'Buy more credits',
                myProfile: 'My Account',
                profile: 'Profile',
                myDashboard: 'My Dashboard',
                changeEmail: 'Change Email',
                backToManufy: 'Back to manufy.com',
                dailyDemo: 'Schedule a demo',
                faq: 'FAQ',
            },
            notice: {
                orderBankTransferPayment: {
                    title: 'Bank transfer',
                    message: 'The manufacturer will provide you with further instructions on how to complete the payment',
                },
            },
            form: {
                address1: {
                    label: 'Address line 1',
                    placeholder: 'Street name + number',
                },
                address2: {
                    label: 'Additions',
                    placeholder: 'Apartment, suite, unit, building, floor, etc.',
                },
                avatar: {
                    label: 'Logo',
                },
                availabilityStatus: {
                    label: 'Availability status',
                    placeholder: 'Update your availability',
                    adminPlaceholder: 'Select an availability status'
                },
                bicNumber: {
                    label: 'BIC number',
                    placeholder: 'Enter BIC number',
                    supportText: 'BIC number of the company bank account',
                },
                brandConnection: {
                    label: 'Brand {varCount}',
                    placeholder: 'Enter brand name',
                },
                brandConnections: {
                    label: 'Brands you have worked with previously',
                },
                businessName: {
                    label: 'Business name',
                    placeholder: 'Enter business name',
                    supportText: 'This is the company name your clients will see',
                },
                buyer: {
                    label: 'Buyer',
                    placeholder: 'Buyer',
                },
                buyerName: {
                    label: 'Buyer name',
                    placeholder: 'Enter a buyer name',
                },
                buyerObjective: {
                    placeholder: 'Explain what you are looking for',
                },
                buyerSearch: {
                    placeholder: 'e.g. designer',
                    disclaimer_label: 'Having trouble finding what you’re looking for?',
                    disclaimer_cta: 'Schedule a call to learn what we can offer',
                },
                category: {
                    label: 'Category',
                },
                categories: {
                    label: 'Categories',
                    placeholder: 'Search by keywords, such as t-shirt, hoodie, etc.',
                },
                city: {
                    label: 'City',
                    placeholder: 'Enter the name of a city',
                },
                co2Reduction: {
                    label: 'Yearly Impact Reduction (kg Co2)',
                },
                cocNumber: {
                    label: 'CoC number',
                    placeholder: 'Enter CoC number',
                    supportText: 'The CoC-number is a number you request at the Chamber of Commerce.'
                },
                confirmPassword: {
                    label: 'Confirm password',
                    placeholder: 'Re-enter your password',
                },
                companyAddress: {
                    label: 'Company address',
                },
                companyChoice: {
                    label: 'Company choice',
                },
                companyChoiceBuyer: {
                    label: 'Fashion Brand',
                    supportText: 'Looking for sustainable solutions',
                },
                companyChoiceSeller: {
                    label: 'Supply Chain Partner',
                    supportText: 'Looking for production opportunities',
                },
                companyChoiceServiceProvicer: {
                    label: 'Circularity Solution',
                    supportText: 'Looking to connect with fashion brands',
                },
                comment: {
                    label: 'Comment',
                    placeholder: 'Leave a comment here...',
                },
                companyName: {
                    label: 'Company name',
                    placeholder: 'Enter company name',
                },
                companySubscription: {
                    label: 'Pricing plan',
                },
                companyType: {
                    label: 'Company type',
                    placeholder: 'Select a company type'
                },
                country: {
                    label: 'Country',
                    placeholder: 'Enter the name of a country',
                },
                courierName: {
                    label: 'Courier name',
                    placeholder: 'e.g. DHL Express, UPC, etc.',
                },
                createdAtFilter: {
                    timeframePicker: 'Created',
                    datePicker: 'Date',
                },
                deliveryDate: {
                    label: 'Delivery date',
                    supportText: 'Select preferred delivery date',
                },
                email: {
                    label: 'Email',
                    placeholder: "example{'@'}site.com",
                },
                energyReductionPolicy: {
                    label: 'Energy reduction policy',
                    placeholder: 'Write about your energy reduction projects here',
                },
                factorySize: {
                    label: 'Factory size in m²',
                    placeholder: 'e.g. 1000',
                },
                fabricPercentage: {
                    placeholder: 'e.g. 85',
                },
                fabrics: {
                    label: 'Fabrics',
                    options: {
                        dontHave: 'I need help sourcing fabrics',
                        haveOwn: 'I have my own fabrics',
                    },
                    placeholder: 'Search by keywords, such as cotton, denim, etc.',
                },
                fabricWastePolicy: {
                    label: 'Fabric waste policy',
                    placeholder: 'Write about your fabric waste policy here',
                },
                financialBenefit: {
                    label: 'Financial Benefit (€)',
                },
                firstName: {
                    label: 'First name',
                    placeholder: 'Enter your first name',
                    adminFilterPlaceholder: 'Enter a first name',
                },
                hasSocials: {
                    yes: 'I have social links to share',
                    no: 'I don\'t have social media',
                },
                ibanNumber: {
                    label: 'IBAN number',
                    placeholder: 'Enter IBAN number',
                    supportText: 'IBAN number of the company bank account',
                },
                invoiceNumber: {
                    label: 'Invoice number',
                    placeholder: '23015042',
                },
                introductionBrief: {
                    label: 'Brief introduction',
                    placeholder: 'Brief intro of your company',
                    supportText: 'Write a short story about the mission and vision of the company',
                },
                introductionDetailed: {
                    label: 'Detailed introduction',
                    placeholder: 'Detailed intro of your company',
                    supportText: 'Write a story about your subjective goals, future and further plans for the company',
                },
                lastName: {
                    label: 'Last name',
                    placeholder: 'Enter your last name',
                    adminFilterPlaceholder: 'Enter a last name',
                },
                revenueFromLastYear: {
                    label: 'Last year\'s revenue',
                },
                legalOwner: {
                    label: 'Legal owner',
                    placeholder: 'Enter the name(s) of the owner(s)',
                    supportText: 'Legal owners of this company',
                },
                legislativeStatus: {
                    label: 'Legislative Status',
                },
                linkUrl: {
                    label: 'Link',
                },
                locale: {
                    label: 'Language',
                    placeholder: 'Choose your native language',
                },
                locationName: {
                    label: 'Location name',
                    placeholder: 'Enter a name for this location',
                },
                madeFor: {
                    label: 'Made for',
                    placeholder: 'Select who it\'s made for'
                },
                manufyValues: {
                    label: 'Manufy Values',
                    placeholder: 'Search by keywords such as ethical labor, zero waste, etc.',
                },
                message: {
                    label: 'Message',
                    placeholder: 'Send a message to the brand',
                },
                moq: {
                    label: 'Minimum order quantity',
                    placeholder: '500',
                },
                moqFilter: {
                    moreThan: 'More than',
                    lessThan: 'Less than'
                },
                mpc: {
                    label: 'Maximum capacity',
                    placeholder: '20000',
                },
                name: {
                    label: 'Name',
                    placeholder: 'Enter your name',
                    adminFilterPlaceholder: 'Enter a name',
                },
                newEmail: {
                    label: 'New email address',
                    supportText: 'Update the login email for your account',
                },
                newPassword: {
                    label: 'New password',
                    placeholder: 'Enter your new password',
                    supportText: 'Update the login password for your account',
                },
                onboardingStep: {
                    label: 'Onboarding step',
                    placeholder: 'Select an onboarding step',
                },
                onboardingService: {
                    label: 'Requested/Provided services',
                    placeholder: 'Select a service'
                },
                oppStatus: {
                    label: 'OPP status',
                    placeholder: 'Select an OPP status'
                },
                oppUid: {
                    label: 'OPP uid',
                    placeholder: 'Enter an OPP uid',
                },
                orderNumber: {
                    label: 'Order number',
                    placeholder: 'Enter order number',
                },
                orderType: {
                    label: 'Order type',
                    placeholder: 'Select an order type',
                },
                otherProvidedService: {
                    label: 'Other services',
                    placeholder: 'Enter other services you provide',
                },
                otherRequestedService: {
                    label: 'Other services',
                    placeholder: 'Enter other services you need',
                },
                password: {
                    label: 'Password',
                    placeholder: 'Enter your password',
                },
                paymentStatus: {
                    label: 'Payment status',
                    placeholder: 'Select a payment status',
                },
                phone: {
                    label: 'Phone number',
                    placeholder: 'Enter phone number',
                },
                price: {
                    label: 'Price',
                },
                priceFilter: {
                    moreThan: 'More than',
                    lessThan: 'Less than',
                },
                productDescription: {
                    label: 'Specifications',
                    placeholder: 'Write about your product here',
                },
                productName: {
                    label: 'Product name',
                    placeholder: 'Enter a name for this product',
                },
                productValue: {
                    label: 'Value',
                    supportText: 'Total product value',
                },
                projectName: {
                    label: 'Project name',
                    placeholder: 'Enter a name for this project',
                    supportText: 'Name your project, so you can easily find it back later',
                },
                productionCategories: {
                    label: 'Production categories',
                },
                providedServices: {
                    label: 'Provided services',
                },
                pricePerUnit: {
                    label: 'Price per unit',
                    placeholder: 'e.g. 14.95',
                },
                serviceCategories: {
                    label: 'What type of services do you offer?',
                },
                region: {
                    label: 'Region',
                    placeholder: 'Enter the name of the region',
                },
                registrationCountry: {
                    label: 'Registration country',
                    placeholder: 'Enter the name of a country',
                    supportText: 'Country in which the company in registered',
                },
                registrationReasons: {
                    label: 'What Manufy should solve',
                },
                registrationYear: {
                    label: 'Registration year',
                    placeholder: 'Enter the year of establishment',
                    supportText: 'Year in which the company was registered',
                },
                renewableEnergyPercentage: {
                    label: 'Renewable energy percentage',
                    placeholder: 'e.g. 85',
                },
                requestedServices: {
                    label: 'Requested services',
                },
                serviceInquiryMessage: {
                    label: 'Message',
                    placeholder: 'Start a chat to get more info!',
                },
                serviceRequestName: {
                    label: 'Your request',
                    placeholder: 'Describe the requested service in a few words',
                },
                serviceRequestDescription: {
                    label: 'Detailed description',
                    placeholder: 'Describe the requested service in detail',
                },
                searchCategories: {
                    label: 'Search categories',
                },
                sellerName: {
                    label: 'Seller name',
                    placeholder: 'Enter a seller name',
                },
                socialMedia: {
                    label: 'Social media',
                },
                sortByColumn: {
                    label: 'Sort by',
                    placeholder: 'Select a column'
                },
                sortDirection: {
                    label: 'Sort direction',
                    placeholder: 'Select a direction'
                },
                sourcingCountries: {
                    label: 'Sourcing countries',
                    supportText: 'Define countries you would like to source from. Adding more countries will lead to more results',
                },
                subscriptionStatus: {
                    label: 'Subscription status',
                    placeholder: 'Select a status',
                },
                sustainabilityPolicy: {
                    label: 'Sustainability policy',
                    placeholder: 'Write about your policy here',
                },
                status: {
                    label: 'Status',
                    placeholder: 'Select a status',
                },
                targetPrice: {
                    label: 'Target price',
                    placeholder: 'e.g. 14.95',
                    supportText: 'Estimate price per piece',
                },
                targetQuantity: {
                    label: 'Target quantity',
                    placeholder: 'e.g. 300',
                },
                techpack: {
                    label: 'Techpack',
                    placeholder: 'Select files..',
                    options: {
                        yes: {
                            label: 'I have a technical drawing / tech pack',
                            supportText: 'The technical drawing is visible for manufacturers after submitting this project.',
                        },
                        assistance: {
                            label: 'I require assistance with my technical drawing',
                            supportText: 'Check out the <a href="{varAssistanceUrl}" target="_blank" class="text-blue-400"><u>link</u></a> for more info',
                        },
                        no: {
                            label: 'I will upload the technical drawing later',
                            supportText: 'You can upload a techpack later but your project won\'t be shown to manufacturers until you do.',
                        },
                    },
                    supportText: 'E.g. technical drawings, size specs, CAD designs. (<a href="{varExampleUrl}" target="_blank" class="text-blue-400"><u>Download example</u></a>)',
                },
                topic: {
                    label: 'Topic',
                },
                totalEmployees: {
                    label: 'Total employees',
                    placeholder: 'Select an employee range',
                },
                marketingChannel: {
                    label: 'Marketing channel',
                    placeholder: 'Select the incoming marketing channel',
                },
                otherMarketingChannel: {
                    label: 'Other channel',
                    placeholder: 'How did you find us?',
                },
                quantity: {
                    label: 'Quantity',
                    placeholder: 'e.g. 300',
                },
                quantityFilter: {
                    moreThan: 'More than',
                    lessThan: 'Less than',
                },
                scoreDescription: {
                    label: 'Description',
                },
                scorePrice: {
                    label: 'Price',
                },
                scoreQuantity: {
                    label: 'Quantity',
                },
                scoreSustainability: {
                    label: 'Sustainability',
                },
                scoreTechpack: {
                    label: 'Techpack',
                },
                trackAndTraceNumber: {
                    label: 'Track & Trace number',
                    placeholder: 'e.g. JJD0099999999',
                },
                urlLinkedin: {
                    label: 'Linkedin',
                    placeholder: 'https://linkedin.com/company-name',
                },
                urlFacebook: {
                    label: 'Facebook',
                    placeholder: 'https://facebook.com/company-name',
                },
                urlInstagram: {
                    label: 'Instagram',
                    placeholder: 'https://instagram.com/company-name',
                },
                urlTiktok: {
                    label: 'Tiktok',
                    placeholder: 'https://tiktok.com/company-name',
                },
                urlYoutube: {
                    label: 'Youtube',
                    placeholder: 'https://youtube.com/company-name',
                },
                urlPinterest: {
                    label: 'Pinterest',
                    placeholder: 'https://pinterest.com/company-name',
                },
                vatNumber: {
                    label: 'VAT number',
                    placeholder: 'Enter VAT number',
                    supportText: 'Fill in or update your VAT number here.',
                },
                website: {
                    label: 'Website',
                    placeholder: 'www.yoursite.com',
                },
                yearOfEstablishment: {
                    label: 'Year of establishment',
                },
                yearlyPowerConsumption: {
                    label: 'Yearly power consumption in kW/h',
                    placeholder: 'e.g. 20000',
                },
                yearlyRevenue: {
                    label: 'Yearly revenue range',
                    placeholder: 'Select a revenue range',
                },
                zipcode: {
                    label: 'Zipcode',
                    placeholder: '1234 AB',
                },
            },
            radioTabs: {
                project: 'Project',
                products: 'Products',
                services: 'Services',
                fabrics: 'Fabrics',
                reviews: 'Reviews',
                sellers: 'Manufacturers',
                partners: 'Partners',
            },
            sectionMessages: {
                emailNotVerified: {
                    message: 'Your email has not been verified, yet. Please check your email for the verification email or click the button below to send a new one.',
                    title: 'Email not verified',
                },
                newPassword: {
                    message: 'Please make sure the new email is correct.',
                    title: 'Confirmation',
                },
                companyAvailabilityStatus: {
                    buttonBLabels: {
                        fullCapacity: 'Still at full capacity',
                        lookingForPartners: 'Still looking for partners',
                        notActivelySearching: 'Still not actively searching',
                        onHoliday: 'Still on holiday',
                        openCapacity: 'Still at open capacity',
                        projectInProcess: 'Still have a project in process',
                    },
                    title: 'Are you open to work?',
                    message: 'You\'ve been {varStatus} for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                    labels: {
                        fullCapacity: 'You\'ve been at full capacity for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                        lookingForPartners: 'You\'ve been looking for partners for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                        notActivelySearching: 'You\'ve not been actively searching for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                        onHoliday: 'You\'ve been on holiday for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                        openCapacity: 'You\'ve been at open capacity for a while. Would you like to update your availability status to indicate whether or not you are open to work?',
                        projectInProcess: 'You\'ve had a project in process for a while. Would you like to update your availability status to indicate whether or not you are open to work?',

                    }
                },
                companyChoiceSeller: {
                    buttonALabel: 'I am a brand',
                    buttonBLabel: 'View Quotation',
                    message: 'Note that you need a brand account to actually submit your request for quotation (RFQ)!',
                    title: 'Don\'t lose your quotation',
                },
            },
            confirmation: {
                generalDelete: {
                    title: 'Delete?',
                    message: 'Are you sure you want to delete this item? The action can not be reverted.',
                    yes: 'Delete',
                    no: 'Cancel',
                },
            },
            toast: {
                danger: {
                    changeEmail: {
                        message: 'Email already taken',
                        title: 'Invalid email',
                    },
                    emailExists: {
                        message: 'The email is already connected to a Manufy account',
                        title: 'Unable to send invitation',
                    },
                    generalError: {
                        message: 'Please try again later or contact customer support',
                        title: 'Something went wrong',
                    },
                    invalidArguments: {
                        message: 'Please fill in the correct details',
                        title: 'Something went wrong',
                    },
                    invalidDocument: {
                        message: 'Please upload a new document',
                        title: 'Invalid document',
                    },
                    invalidInvitation: {
                        message: 'The invitation is expired',
                        title: 'Invitation expired',
                    },
                    noCredits: {
                        message: 'You do not have enough credits',
                        title: 'Something went wrong',
                    },
                    product: {
                        message: 'Product deleted',
                    },
                    request: {
                        message: 'Request deleted',
                    },
                    productCreated: {
                        message: 'Could not create product',
                        title: 'Product creation failed',
                    },
                    quotationNotFound: {
                        message: 'The quotation could not be found because it was either unpublished or deleted',
                        title: 'Quotation not found',
                    },
                    sendProject: {
                        message: 'The order quantity does not exceed the MOQ of this Manufacturer',
                        title: 'Unable to send projects',
                    },
                    vatValidated: {
                        message: 'Not a valid VAT number',
                        title: 'Invalid VAT number',
                    },
                    leaveRfqReview: {
                        message: 'The project has been deleted, or something else went wrong. Please contact costumer support',
                        title: 'Error',
                    },
                    tooManyProducts: {
                        message: 'You can\'t have more than 5 products per project',
                        title: 'Too many products',
                    },
                    serviceCreated: {
                        message: 'Could not create service',
                        title: 'Service creation failed',
                    },
                    fabricCreated: {
                        message: 'Could not create fabric',
                        title: 'Something went wrong, please contact customer support',
                    }
                },
                error: {
                    form: {
                        message: 'Fix your errors before continuing.',
                        title: 'Unresolved form errors',
                    },
                    userDeactivated: {
                        message: 'Your account has been deactivated. Please contact customer support for further questions',
                        title: 'Account deactivated',
                    },
                },
                info: {
                    fieldsCleared: 'The RFQ Wizard has been reset and all fields are clear.',
                    productAdded: 'New product added',
                },
                success: {
                    message: 'Successfully uploaded!',
                    title: 'Upload Successful',
                    adminVatValidated: {
                        message: 'The user\'s VAT has been validated and updated successfully',
                        title: 'Vat number validated',
                    },
                    availabilityStatusUpdated: {
                        message: 'Availability status has been updated successfully!',
                        title: 'Status updated',
                    },
                    avatarUploaded: {
                        message: 'You have successfully changed your avatar!',
                        title: 'Avatar Uploaded',
                    },
                    changeEmail: {
                        message: 'Email changed successfully!',
                        title: 'Email changed',
                    },
                    commentAdded: {
                        message: 'Comment has been successfully added!',
                        title: 'Comment added',
                    },
                    commentPosted: {
                        message: 'Comment successfully posted',
                        title: 'Comment posted!',
                    },
                    commentUpdated: {
                        message: 'Comment successfully updated',
                        title: 'Comment updated!',
                    },
                    commentDeleted: {
                        message: 'Comment successfully deleted',
                        title: 'Comment deleted!',
                    },
                    companyReported: {
                        message: 'Company successfully reported!',
                        title: 'Company reported',
                    },
                    contactDetailsSaved: {
                        message: 'Contact details saved successfully!',
                        title: 'Saved',
                    },
                    creditsSent: {
                        message: 'Credits have been successfully sent to the manufacturer!',
                        title: 'Credits sent',
                    },
                    directRfqSaved: {
                        message: 'Project {varProject} has been sent!',
                        title: 'Project sent',
                    },
                    emailPreferencesUpdated: {
                        message: 'Email preferences updated successfully!',
                        title: 'Changes Saved',
                    },
                    fabricDeleted: {
                        message: 'Fabric has been successfully deleted',
                        title: 'Fabric deleted',
                    },
                    fileUploaded: {
                        message: 'File has been successfully uploaded',
                        title: 'File uploaded',
                    },
                    generalRfqSaved: {
                        message: 'Project {varProject} has been posted!',
                        title: 'Project posted',
                    },
                    invitationsSent: {
                        message: 'Your invitations have been sent!',
                        title: 'Invitations sent',
                    },
                    legalDetailsUpdated: {
                        message: 'Bank verification updated successfully!',
                        title: 'Changes Saved',
                    },
                    linkCopied: {
                        message: 'Link successfully copied to clipboard!',
                        title: 'Link copied',
                    },
                    locationSaved: {
                        message: 'Location updated successfully!',
                        title: 'Changes Saved',
                    },
                    manufyValueApplication: {
                        message: 'Manufy value application successfully sent',
                        title: 'Manufy Value',
                    },
                    manufyValueApplicationDeleted: {
                        message: 'Application successfully deleted',
                        title: 'Application deleted',
                    },
                    mediaSaved: {
                        message: 'Media updated successfully!',
                        title: 'Changes Saved',
                    },
                    offerPlaced: {
                        message: 'Your message was sent!',
                        title: 'Message sent!',
                    },
                    paymentMethodChosen: {
                        message: 'Ask the manufacturer for further payment details',
                        title: 'Payment method chosen',
                    },
                    preferencesSaved: {
                        message: 'Preferences updated successfully!',
                        title: 'Changes Saved',
                    },
                    productCreated: {
                        message: 'Product saved successfully!',
                        title: 'Product saved',
                    },
                    productDeleted: {
                        message: 'Product deleted successfully!',
                        title: 'Product deleted',
                    },
                    productStatusUpdated: {
                        message: 'Product status successfully updated!',
                        title: 'Status updated',
                    },
                    profileSaved: {
                        message: 'Profile page updated successfully!',
                        title: 'Changes Saved',
                    },
                    projectSaved: {
                        message: 'Project saved successfully!',
                        title: 'Project saved',
                    },
                    projectDeleted: {
                        message: 'Project deleted successfully!',
                        title: 'Project deleted',
                    },
                    projectUnpublished: {
                        message: 'Project unpublished successfully!',
                        title: 'Project unpublished',
                    },
                    projectPublished: {
                        message: 'Project published successfully!',
                        title: 'Project published',
                    },
                    purchaseOrderCancelled: {
                        message: 'Order cancelled successfully!'
                    },
                    serviceCreated: {
                        message: 'Service saved successfully!',
                        title: 'Service saved',
                    },
                    fabricCreated: {
                        message: 'Fabric saved successfully!',
                        title: 'Fabric saved',
                    },
                    serviceDeleted: {
                        message: 'Service deleted successfully!',
                        title: 'Service deleted',
                    },
                    subscriptionUpdated: {
                        message: 'Subscription has been updated successfully!',
                        title: 'Subscription updated',
                    },
                    sustainabilityUpdated: {
                        message: 'Sustainability information updated successfully!',
                        title: 'Changes Saved',
                    },
                    transactionCancelled: {
                        message: 'The transaction has been cancelled!',
                        title: 'Transaction cancelled',
                    },
                    userInvited: {
                        message: 'User invited successfully!',
                        title: 'Invitation Sent',
                    },
                    userDetailsSaved: {
                        message: 'User details updated successfully!',
                        title: 'Changes Saved',
                    },
                    vatValidated: {
                        message: 'Your VAT has been validated and updated successfully',
                        title: 'VAT number validated',
                    },
                },
                warning: {
                    fileDeleted: {
                        message: 'Successfully deleted file!',
                        title: 'File Deleted',
                    },
                    filesTooMany: {
                        message: 'You can\'t upload more than {varCount} files at once. Please remove some.',
                        title: 'Too many files',
                    },
                    filesUploading: {
                        message: 'Just a sec; files are still being uploaded!',
                        title: 'File Uploading',
                    },
                    updateProject: {
                        message: 'Update the quantity or target price of your project to reach more manufacturers',
                        title: 'Products with a value higher than €500 receive more quotations',
                    },
                },
            },
            tooltips: {
                partnerStatus: 'This user has created amazing collections for numerous brands on Manufy',
                verifiedStatus: 'A trusted user that has completed the Manufy self assessment screening',
                unverifiedStatus: 'This user has yet to complete the Manufy self assessment screening',
            },
            modal: {
                close: {
                    title: 'Leave?',
                    message: 'Are you sure?',
                },
            },
            desktopNotifications: {
                title: {
                    new_message: 'New message',
                },
                body: {
                    new_message: '{varCompany}: "{varMessage}"',
                    attachment: '{varCompany} sent an attachment.',
                },
            },
            registerModal: {
                title: 'Welcome to Manufy!',
                supportText: 'Create an account to start managing your circular projects',
                firstName: 'First name',
                lastName: 'Last name',
                email: 'Email address',
                password: 'Password',
                passwordConfirm: 'Repeat password',
                phoneNumber: 'Phone number',
                googleBtn: 'Sign up with Google',
                verificationCode: 'Verification code',
                terms: {
                    agreeWith: 'I agree with the',
                    termsAndConditions: 'Terms & Conditions',
                    and: 'and',
                    privacyPolicy: 'Privacy Policy',
                },
                createAccountBtn: 'Create account',
                continueBtn: 'Continue',
                sendCodeBtn: 'Send code',
                verifyPhoneBtn: 'Verify number',
                loginTitle: 'Already have an account?',
                loginBtn: 'Sign in now',
                success: {
                    title: 'Success',
                    msg: 'Your account has been created!',
                },
                errors: {
                    password: 'Your password needs to be at least 8 characters and contain 1 letter and 1 number or special character',
                    passwordConfirm: 'Your passwords don\'t match',
                    terms: 'You need to agree to our terms and conditions',
                },
                alreadyHaveAccount: 'Already have an account?',
                googleLogin: 'Continue with Google',
                googleLoginBtn: 'Sign in',
            },
            loginModal: {
                title: 'Good to see you again!',
                supportText: 'Enter your credentials to continue managing your circular projects',
                email: 'Email address',
                password: 'Password',
                remember: 'Remember me',
                submitBtn: 'Sign in',
                registerTitle: 'Don\'t have an account yet?',
                registerBtn: 'Register now',
                googleBtn: 'Sign in with Google',
                passwordRequestBtn: 'Forgot password?',
                errors: {
                    invalidAuth: 'Invalid credentials. Please try again.',
                },
            },
            buyerSubscription: {
                list: {
                    new: {
                        title: 'Start your free trial to check out our platform',
                        subtitle: 'Start your free trial and enjoy all Manufy has to offer.',
                        saveOnYearly: '<strong class="text-green-400">Save 50%</strong> with yearly payment',
                        testimonial: {
                            quote: 'I highly recommend Manufy to any brand looking to create sustainable collections with ease.',
                            name: 'Eugénie Haitsma Mulier',
                            occupation: 'Co-owner of <strong class="text-blue-400">Martan</strong>',
                        }
                    },
                    existing: {
                        title: 'Welcome back!',
                        subtitle: 'Reactivate your account: Start your subscription plan and enjoy all Manufy has to offer.',
                    },
                    features: {
                        feature0: '50% discount when taking a yearly subscription',
                        feature1: 'Access to Manufy’s database of manufacturers',
                        feature2: 'Browse the product catalogue',
                        feature3: 'Chat, negotiate and close deals with manufacturers',
                        feature4: 'Access to Manufy’s buyer protection program',
                        feature5: 'Free CO<sub>2</sub> compensation on all orders',
                        feature6: 'Support on your projects',
                    },
                },
                pending: {
                    title: 'Your account is almost ready!',
                    subtitle: 'Sit back and relax, get some coffee. Your account will be ready in just a few seconds.',
                    disclaimer: `*In case of an issue or if it is taking too long, please contact our <a href="#" onClick="window.Intercom('show')" class="text-blue-400 underline">customer support</a>.`,
                },
                success: {
                    title: 'Welcome to the Manufy sourcing platform!',
                    subtitle: 'Your payment was successful. You will now have access to all features of our platform.',
                    disclaimer: '',
                },
                failed: {
                    title: 'Something went wrong with the payment',
                    subtitle: `Please try again or contact our <a href="#" onClick="window.Intercom('show')" class="text-blue-400 underline">customer support</a> for help.`,
                    disclaimer: '',
                },
                nextStep: {
                    createNewProject: {
                        title: 'Kickstart your fashion project',
                        description: 'Get the most out of Manufy by creating your first quotation request to let our manufacturers know you are available for work.',
                    },
                },
                manage: 'Manage Subscription',
            },
            sellerSubscription: {
                list: {
                    new: {
                        title: 'Almost there!',
                        subtitle: 'Start your free trial and enjoy all Manufy has to offer.',
                    },
                    existing: {
                        title: 'Welcome Back!',
                        subtitle: 'Reactivate your account: Select your preferred package and enjoy all Manufy has to offer.',
                    },
                    features: {
                        feature0: 'Company Dashboard',
                        feature1: 'Access to the Quotation Wall',
                        feature2: 'Chat, negotiate and close deals with brands',
                        feature3: 'Receive curated RFQ’s based on your preferences',
                        feature4: 'Receive direct RFQ’s from brands in your interest',
                        feature5: 'Publish your products in our catalogue',
                        feature6: 'Full customer support from our team',
                    },
                },
                pending: {
                    title: 'Your account is almost ready!',
                    subtitle: 'Sit back and relax, get some coffee. Your account will be ready in just a few seconds.',
                    disclaimer: `*In case of an issue or if it is taking too long, please contact our <a href="#" onClick="window.Intercom('show')" class="text-blue-400 underline">customer support</a>.`,
                },
                success: {
                    title: 'Welcome to the Manufy sourcing platform!',
                    subtitle: 'Your payment was successful. You will now have access to all features of our platform.',
                    disclaimer: '',
                },
                failed: {
                    title: 'Something went wrong with the payment',
                    subtitle: `Please try again or contact our <a href="#" onClick="window.Intercom('show')" class="text-blue-400 underline">customer support</a> for help.`,
                    disclaimer: '',
                },
                nextStep: {
                    browseQuotations: {
                        title: 'Start producing',
                        description: 'Get the most out of Manufy by replying to quotation requests from our brands to let them know you are available for work.',
                    },
                },
                manage: 'Manage Subscription',
            },
            termsModal: {
                user: {
                    title: 'Updated terms & conditions',
                    description: 'Please accept our latest terms & conditions to work with our platform!',
                    terms: {
                        agreeWith: 'I agree with the',
                        termsAndConditions: 'General Terms & Conditions',
                    },
                },
                buyer: {
                    title: 'A Message from the Manufy Team,',
                    description:
                        `Introducing: Secure Payments & Buyer Protection<br />
                            <br />
                            We all love a bit of certainty don’t we? That’s why we came up with a way to make your sustainable sourcing journey a bit safer!<br />
                            <br />
                            How?<br />
                            <br />
                            Well, with our Buyer Protection Program. Whenever you place an order through Manufy we will hold onto your payment and only forward it to the manufacturer when your goods have arrived.<br />
                            <br />
                            Feels safe right? We feel very safe about it.<br />
                            <br />
                            Besides this new feature, you will also be able to manage all your orders and payments through Manufy. Negotiating terms and conditions, receiving samples, having your invoices in one place: it’s a lot easier and safer now. And in case a dispute arises with a manufacturer, Manufy is there to help.<br />
                            <br />
                            Are you ready to try this new tool in Manufy’s toolbox?<br />
                            <br />
                            Then let’s get sourcing.`,
                    accept: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">General Terms & Conditions for Buyers</a>',
                    readMore: '<a href="{varUrl}" target="_blank" class="text-blue-400">Click here</a> to learn more about Secure Payments & Buyer Protection',
                    perksTitle: 'Perks',
                    perks: [
                        'Built-in Buyer Protection',
                        'Manufacturer gets paid once order is delivered',
                        'Manufy helps in case of dispute',
                        'Safe & secure projects',
                    ],
                },
                seller: {
                    title: 'A Message from the Manufy Team,',
                    description:
                        `Introducing: Secure Payments & Buyer Protection<br />
                            <br />
                            The last couple of months we’ve gathered a lot of feedback from our user base and some of the most requested features from our users were the implementation of in-platform payments and a form of buyer protection.<br />
                            <br />
                            We have been working very hard to come up with a fair and simple solution to help both parties get the best Manufy experience possible.<br />
                            <br />
                            That is why, from now on, it will be possible to place orders and collect payments through the Manufy platform. This means you can easily work with re-orders, have all your invoices in one place and can rely on the Manufy team in case a dispute arises. Part of the new secure payments is our Buyer Protection Program, were we hold on to the payment buyers make until their goods are delivered.<br />
                            <br />
                            In the near future, manufacturers with a high trust score can count on us to receive a part of the payment before delivery. This will be based on the amount of orders you’ve done through Manufy successfully and buyer satisfaction.<br />
                            <br />
                            Are you ready for this next step? Then please agree with our updated Terms & Conditions!<br />
                            <br />
                            Let’s get sourcing`,
                    accept: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">General Terms & Conditions for Manufacturers</a>',
                    readMore: '<a href="{varUrl}" target="_blank" class="text-blue-400">Click here</a> to learn more about Secure Payments & Buyer Protection',
                    perksTitle: 'Perks',
                    perks: [
                        'Secure payments via Manufy',
                        'Organise all your projects in-platform',
                        'Easy re-orders with loyal customers',
                    ],
                },
            },
            kycModal: {
                title: 'Start your company verification',
                description: `In order to do business and receive payments via Manufy, we have to verify your company via our partner OPP. <br> <br>
                Please fill out your CoC number and agree with the terms & conditions and we will send you an e-mail to start the verification.`,
                accept: 'I agree with <a href="{varUrlTerms}" target="_blank" class="text-blue-400">things</a>',
            },
            passwordRequestModal: {
                title: 'Password reset link',
                supportText: 'We will send a password reset link to the email address below',
                email: 'E-mail',
                submitBtn: 'Send link',
                loginBtn: 'Sign in',
                success: {
                    title: 'Success',
                    msg: 'A password reset link has been send to {varEmail}',
                },
            },
            passwordResetModal: {
                title: 'Reset password',
                password: 'Password',
                supportText: 'Set a new password for your Manufy account',
                passwordConfirm: 'Repeat password',
                submitBtn: 'Reset password',
                passwordRequestBtn: 'Send new reset link',
                success: {
                    title: 'Success',
                    msg: 'Your password has been reset',
                },
                errors: {
                    password: 'Your password needs to be at least 8 characters and contain 1 letter and 1 number or special character',
                    passwordConfirm: 'Your passwords don\'t match',
                    tokenExpired: 'This password reset token is no longer valid! Please request a new one.',
                }
            },
            avatarModal: {
                title: 'Update avatar',
            },
            bidModal: {
                title: 'Place a bid',
                chooseProducts: 'Choose which product(s) you would like to bid on below, and provide general updates to the project on the side.',
                submitBtn: 'Send offer',
                totalPrice: 'Total price',
                techpackUnavailable: 'Techpack unavailable',
                unverifiedAlert: {
                    title: 'Account not verified',
                    message: 'Your account is not verified yet. Please contact customer support to schedule an onboarding call.',
                },
                deliveryDate: {
                    label: 'Delivery date',
                },
                message: {
                    label: 'Message',
                    placeholder: 'Type here...',
                },
                price: {
                    label: 'Price per unit',
                },
                quantity: {
                    label: 'Quantity',
                },
                confirm: {
                    title: 'Send Offer?',
                    msg: 'Are you sure you want to send this offer?',
                    yes: 'Send offer',
                    no: 'Not yet',
                },
                success: {
                    title: 'Success!',
                    msg: 'Your offer has been sent!',
                },
            },
            labelModal: {
                title: 'Chat labels',
                alert: {
                    deleteLabel: {
                        title: 'Delete label',
                        message: 'Are you sure you want to delete this label? It will be removed from all threads!',
                    },
                },
            },
            locationModal: {
                title: 'Select a location',
                addNewAddress: {
                    title: 'Add New Address',
                    name: 'Name',
                    address1: 'Street name + number',
                    address2: 'Additions',
                    zipCode: 'Zip Code',
                    city: 'City',
                    region: 'Region',
                    country: 'Country',
                    addBtn: 'Add new address',
                },
                selectLocationBtn: 'Select Location',
                addAddressBtn: 'Add Address',
            },
            vatModal: {
                title: 'Enter VAT number',
                body: 'Please fill out your VAT number, so we can check it again. <a href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation" target="_blank" class="text-blue-400">Click here</a> for more information.',
                placeholder: 'Example: NL123456789',
                btnLabel: 'Verify VAT number',
            },
            reportCompanyModal: {
                title: 'Report this company',
                body: 'Please add the reason why you are reporting this company.',
                btnLabel: 'Report',
                alert: {
                    title: 'Report Company?',
                    message: 'Are you sure you want to report this company?',

                }
            },
            applicationModal: {
                create: {
                    title: 'Manufy Value - Validation Request',
                    description: 'Please upload the evidence for the Manufy Value <b>{valueName}</b> here. Our team will review your evidence. After confirmation, your new Manufy value will be added to your Manufacturer profile. Also, it will allow you to apply this Manufy value to your products.',
                    description2: 'For more information and guidelines of what your evidence should entail, please check out our <a href="https://help.manufy.com/en/articles/7476715-how-to-upload-and-verify-your-manufy-values" target="_blank" class="text-blue-400">FAQ page on uploading Manufy Values</a>.',
                },
                list: {
                    title: 'Manufy Value - Documents',
                },
                edit: {
                    title: 'We need some extra information',
                    description: 'Your document was not yet sufficient to award you the Manufy Value <b>{valueName}</b>. Please check our feedback and upload a new document.',
                },
                uploadedDocuments: 'Uploaded documents',
                feedback: 'Feedback',
            },
            feedbackModal: {
                label: 'Feedback',
                supportText: 'This feedback will only be visible to the poster of the project'
            },
            companyAvailabilityStatusModal: {
                title: 'Company availability',
                description: 'Let others know whether or not you are open to work'
            },
            order: {
                paymentSubtitle: 'Choose your payment provider',
                openTransactions: 'Open transactions',
                cancelTransactionConfirm: {
                    title: 'Cancel transaction?',
                    message: 'Are you sure you want to cancel this transaction? Any money sent for this transaction will be reimbursed to your bank account.',
                    yes: 'Yes',
                    no: 'No',
                },
                list: {
                    number: 'Order #',
                    totalCosts: 'Total Costs',
                    status: 'Status',
                    type: 'type',
                    createdAt: 'Created At',
                    updatedAt: 'Updated At',
                    orderType: 'Order Type',
                },
                create: {
                    success: 'Your order has been sent!',
                },
                cancel: {
                    success: 'Order cancelled successfully!',
                },
                decline: {
                    success: 'Order declined successfully!',
                },
                accept: {
                    success: 'Order accepted successfully!',
                },
                costs: {
                    title: 'Costs overview',
                    subtitle: 'Overview of the manufacturing costs',
                    total: 'Total',
                    update: 'Update costs',
                    production: {
                        title: 'Production',
                        subtitle: 'The costs for producing the products',
                        label: 'Production costs',
                    },
                    buyerProtectionFee: {
                        title: 'Buyer protection fee ({percentage}%)',
                        subtitle: 'The costs for producing the products',
                        label: 'Production costs',
                    },
                    buyerProtectionFeeVat: {
                        title: 'VAT ({percentage}% of Buyer protection)',
                        disclaimer: 'To be eligible for VAT reverse-charge, your VAT number is required. If you do not enter a validated VAT number, Dutch VAT of 21% will be charged on the invoice.',
                    },
                    sellerFee: {
                        title: 'Manufy fee ({percentage}% of subtotal)',
                        subtitle: 'The costs for using the platform',
                        label: 'Manufacturer fee',
                        disclaimer: 'The costs for using the platform. The Manufy fee is excluding VAT.',
                    },
                    sellerFeeVat: {
                        title: 'VAT ({percentage}% of Manufy fee)',
                        disclaimer: 'To be eligible for VAT reverse-charge, your VAT number is required. If you do not enter a validated VAT number, Dutch VAT of 21% will be charged on the invoice.',
                    },
                    sellerTotal: {
                        title: 'You will receive',
                        label: 'You will receive',
                    },
                    shipping: {
                        title: 'Shipping',
                        subtitle: 'The costs for shipping the products',
                        label: 'Shipping costs',
                    },
                    other: {
                        title: 'Other',
                        subtitle: 'Extra costs',
                        label: 'Other costs',
                    },
                    subtotal: {
                        title: 'Subtotal',
                        subtitle: 'Subtotal costs',
                        label: 'Subtotal costs',
                    },
                    vat: {
                        title: 'VAT ({percentage}% of subtotal)',
                        subtitle: 'The taxes',
                        label: 'VAT (%)',
                    },
                    carbonCompensation: 'Co2 compensation',
                    success: 'Your cost estimation has been sent!',
                },
                shipmentSent: {
                    title: 'Shipment details',
                    label: 'Fill in shipment details',
                    date: {
                        label: 'Final shipping date',
                    },
                    success: 'The shipping details have been sent!',
                },
                shipmentReceived: {
                    label: 'Confirm shipment',
                    confirm: {
                        msg: 'You are about to confirm that you received order #{orderNumber} and that the order meets the requirements and quality agreed upon with the manufacturer',
                        title: 'Order received',
                        yes: 'Everything is ok',
                        no: 'Cancel',
                    },
                    success: 'Shipment received confirmed successfully!',
                },
                startDispute: {
                    label: 'Report a problem',
                    success: 'Your problem has been reported to our moderators!',
                    title: 'Report a problem',
                    description: {
                        title: 'Description',
                        placeholder: 'describe the problem ...',
                    },
                },
                leaveReview: {
                    label: 'Leave a review',
                    success: 'Thank you for the feedback!',
                    title: 'Leave a review',
                    body: {
                        title: 'Review',
                        placeholder: 'tell us about your experience ...',
                        nameDisplayToggle: 'Show my company name publicly above the review',
                    },
                },
                updateReview: {
                    success: 'Review updated successfully',
                    body: {
                        title: 'Update review for',
                    },
                    alert: {
                        title: 'Update review?',
                        message: 'You sure you want to update this review?',
                    }
                },
                endDispute: {
                    label: 'Problem solved',
                    confirm: {
                        msg: 'You are about to confirm that the problem was solved?',
                        title: 'Problem solved',
                        yes: 'Everything is ok',
                        no: 'Cancel',
                    },
                    success: 'Problem solved!',
                },
                deliveryDetails: {
                    title: 'Delivery details',
                },
                paymentDetails: {
                    title: 'Payment details',
                    instructions: 'Payment instructions',
                },
                paymentPendingDetails: {
                    instructions: 'Payment still pending?',
                },
                billingAddress: {
                    hasSameBillingAddress: 'Billing address same as delivery address',
                    title: 'Billing address',
                    subtitle: 'Select the billing address',
                },
                paymentMethod: {
                    title: 'Payment method',
                    subtitle: 'Manufy Pay',
                },
                paymentTerms: {
                    title: 'Payment terms',
                    subtitle: 'We store 100% of the total order value in escrow until 20 days after the products have been shipped or when the brand indicates they have received the order.',
                    advance: '100% advance (Escrow)',
                },
                deliveryAddress: {
                    title: 'Delivery address',
                    subtitle: 'Select the delivery address',
                },
                buyerCourier: {
                    title: 'Buyer courier',
                    name: 'Courier Name',
                    number: 'Courier Account Number',
                    yes: 'Use my own courier account number (e.g. DHL, UPC, etc.)',
                    no: 'Shipment is arranged by the manufacturer',
                },
                shippingFromAddress: {
                    title: 'Shipping from',
                    subtitle: 'Select the address from where the order is shipped',
                },
                shipmentDetails: {
                    title: 'Shipment details',
                    trackAndTrace: 'Track & Trace',
                    sentOn: 'Shipped at',
                },
                preferredDeliveryDate: {
                    title: 'Preferred delivery date',
                    subtitle: 'When would you like to receive the products',
                },
                estimatedShippingDate: {
                    title: 'Expected shipping date',
                    subtitle: 'Expected date of shipping',
                },
                cocNumber: {
                    title: 'CoC Number',
                    subtitle: 'Provide your Chamber of Commerce number for company validation',
                },
                companyLegalName: {
                    title: 'Legal company name',
                    subtitle: 'Your company name how it is legally registered',
                },
                agreeOppTerms: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">Terms & Conditions of {varNameOpp}</a>.',
                remarks: {
                    title: 'Remarks',
                    subtitle: 'Extra details about the products',
                    empty: 'No remarks',
                },
                attachments: {
                    title: 'Documents',
                    subtitle: 'Additional documents or contracts for the order',
                    empty: 'No documents',
                },
                requestOrder: {
                    confirm: {
                        msg: 'Are you sure you want to request the order?',
                        title: 'Request order?',
                    },
                    update: {
                        msg: 'Are you sure you want to update the order?',
                        title: 'Update order?',
                        yes: 'Update Order',
                    },
                    accept: {
                        msg: 'Are you sure you want to accept the order?',
                        title: 'Accept order?',
                        yes: 'Accept Order',
                    },
                    decline: {
                        msg: 'Are you sure you want to decline the order? This will close the order and a new one will have to be created.',
                        title: 'Decline order?',
                        yes: 'Decline Order',
                    },
                    cancel: {
                        msg: 'Are you sure you want to cancel the order?',
                        title: 'Cancel order?',
                        yes: 'Cancel Order',
                    },
                    success: {
                        msg: 'Your order has been sent!'
                    },
                },
                paymentReceivedOrder: {
                    accept: {
                        msg: 'I confirm that I received the payment of this order.',
                        title: 'Payment received?',
                        yes: 'Payment received',
                        no: 'No',
                    },
                },
                errors: {
                    oppTerms: 'You need to agree to the terms and conditions of {varNameOpp}',
                },
                paymentOptionStatus: {
                    comingSoon: 'Coming soon',
                    verificationRequired: 'not supported for this manufacturer',
                },
            },
            purchaseOrder: {
                status: {
                    waiting_for_cost_estimation: 'Waiting for cost estimation',
                    price_negotiation: 'Price negotiation',
                    waiting_for_payment: 'Waiting for payment',
                    paymentPending: 'Payment pending',
                    paymentSuccessful: 'Payment successful',
                    waiting_for_kyc: 'Waiting for verification',
                    waiting_for_shipment: 'Waiting for shipment',
                    payment_completed: 'Payment completed',
                    waiting_for_money_release: 'Waiting for money release',
                    completed: 'Completed',
                    buyer_cancelled: 'Cancelled',
                    seller_cancelled: 'Cancelled',
                    buyer_declined: 'Declined',
                    seller_declined: 'Declined',
                    refunded: 'Refunded',
                    chargeback: 'Full refund',
                },
                type: {
                    purchase_order: 'order',
                    sample_order: 'sample',
                },
                shipmentStatus: {
                    waiting_for_shipment_sent: 'Waiting for shipment sent',
                    waiting_for_shipment_received: 'Waiting for shipment received',
                },
                modal: {
                    title: 'Purchase Order',
                    products: {
                        title: 'Products',
                        subtitle: {
                            creating: 'Determine the products, sizes and quantities you wish to order',
                            updatingCosts: 'Determine the unit price for the different sizes',
                        },
                        name: 'Product',
                        size: 'Size',
                        quantity: 'Quantity',
                        unitPrice: 'Unit price',
                        totalPrice: 'Total',
                        toBeDetermined: 'TBD',
                    },
                    pages: {
                        productDetails: 'Product Details',
                        deliveryAndPayment: 'Delivery Details',
                        summary: 'Summary',
                    },
                    sellerKycStatus: {
                        inactive: {
                            title: 'Verify your company',
                            msg: 'To protect our customers we verify all of our manufacturers. Without verification it is not possible to complete orders via out platform.',
                            btn: 'Start verification process',
                        },
                        pending: {
                            title: 'Verification pending',
                            msg: 'Your company verification is pending. We will notify you when the verification process is completed.',
                            btn: 'See verification status',
                        },
                        unverified: {
                            title: 'Verification pending',
                            msg: 'We will need more information from you to start our verification',
                            btn: 'Submit more information',
                        },
                    },
                    buyerCreating: {
                        title: 'Order not final',
                        msg: 'This order is not final yet. The manufacturer will send a price proposal after you complete this form.',
                    }
                },
                sellerInitConfirm: {
                    title: {
                        purchase_order: 'Nudge for purchase order',
                        sample_order: 'Nudge for sample order',
                    },
                    msg: {
                        purchase_order: 'Do you want to encourage {brandName} to start a purchase order?',
                        sample_order: 'Do you want to encourage {brandName} to start a sample order?',
                    },
                    success: 'Message sent successfully',
                    sendButton: 'Send nudge',
                },
            },
            sampleModal: {
                title: 'Sample Order',
                orderStatus: {
                    buyer: {
                        costEstimation: 'Waiting for cost estimation..',
                        acceptance: 'Accept or decline',
                        cancelled: 'Buyer cancelled',
                        declined: 'Buyer declined',
                    },
                    seller: {
                        costEstimation: 'Fill out cost estimation!',
                        acceptance: 'Awaiting acceptance..',
                        cancelled: 'Seller cancelled',
                        declined: 'Seller declined',
                    },
                    accepted: 'Accepted',
                    completed: {
                        waitingForAcceptance: 'Waiting for acceptance',
                        declineBtn: 'I decline this offer',
                        acceptBtn: 'I accept this offer',
                        cancelOfferBtn: 'Cancel offer',
                        cancelled: {
                            you: 'You cancelled this sample order',
                            buyer: 'The buyer cancelled this sample order',
                            seller: 'The seller cancelled this sample order',
                        },
                        declined: {
                            you: 'You declined this sample order',
                            buyer: 'The buyer declined this sample order',
                            seller: 'The seller declined this sample order',
                        },
                        accepted: {
                            youDigital: 'You accepted this sample order, now waiting for the order to be sent',
                            youPhysical: 'You accepted this sample order, now waiting for the order to be shipped',
                            buyerDigital: 'The buyer accepted this sample order, now waiting for you to send the order',
                            buyerPhysical: 'The buyer accepted this sample order, now waiting for you to ship the order',
                        },
                    },
                },
                sampleType: {
                    label: 'Samples Type',
                    physical: {
                        label: 'Physical Samples',
                        description: 'Request samples by mail to feel the fabric and to see the actual measurements.',
                    },
                    digital: {
                        label: 'Digital Samples',
                        description: 'Request digital samples like images and tech pack via the chat.',
                    },
                },
                sampleDetails: {
                    label: 'Samples Details',
                    description: 'What samples would you like to receive?',
                    productCol: 'Product',
                    materialCol: 'Material',
                    colorCol: 'Color Dip',
                    printCol: 'Print/Embroidery',
                    dimensionsCol: 'Dimensions',
                },
                remark: {
                    label: 'Remarks',
                    sub: 'Write your remark here ({varCount}/{varMax})',
                    placeholder: 'Type here...',
                },
                deliveryAddress: {
                    label: 'Delivery address',
                    description: 'Select the delivery address.',
                },
                deliveryDetails: {
                    digital: 'Digital Delivery',
                    physical: 'Physical Delivery',
                    label: 'Deliver Details',
                    description: 'Select delivery details',
                    buyerCourierNo: 'Shipment is arranged by the manufacturer',
                    buyerCourierYes: 'Use my own courier account number (e.g. DHL, UPC, etc.)',
                    buyerCourierName: 'Courier Name',
                    buyerCourierNumber: 'Courier Account Number',
                },
                deliveryDate: {
                    label: 'Preferred Delivery Date',
                },
                paymentType: {
                    label: 'Payment Details',
                    description: 'Specify the way you want to pay for the sample costs (if applicable).',
                    bank: {
                        label: 'Manual bank transfer',
                    },
                    manufy: {
                        label: 'Payment via Manufy (Coming soon)',
                    },
                },
                additionalTerms: {
                    label: 'Legal',
                    description: 'I would like to add additional terms',
                },
                costEstimation: {
                    title: 'Cost',
                    production: 'Production',
                    shipping: 'Shipping',
                    label: 'Cost estimation',
                    free: 'Free',
                    description: 'Your production and shipping cost for the sample(s)',
                    waiting: 'Waiting for a cost estimation',
                },
                costProductionEstimation: {
                    label: 'Production cost estimation',
                },
                costShippingEstimation: {
                    label: 'Shipping cost estimation',
                },
                requestSample: {
                    confirm: {
                        title: 'Request samples?',
                        msg: 'Are you sure you want to request these samples?',
                        yes: 'Send Request',
                        no: 'Not yet',
                    },
                    success: {
                        msg: 'Your sample request has been sent!',
                    },
                },
                sendCost: {
                    confirm: {
                        title: 'Send estimation?',
                        msg: 'Are you sure you want to send this cost estimation?',
                        yes: 'Send',
                        no: 'Not yet',
                    },
                    success: {
                        msg: 'Your cost estimation has been send!',
                    },
                },
                cancelOrder: {
                    buyerConfirm: {
                        title: 'Cancel?',
                        msg: 'Are you sure you want to cancel this sample request?',
                    },
                    buyerSuccess: {
                        msg: 'Your sample request has been cancelled!',
                    },
                    sellerConfirm: {
                        title: 'Cancel?',
                        msg: 'Are you sure you want to cancel this offer?',
                    },
                    sellerSuccess: {
                        msg: 'Your offer has been cancelled!',
                    },
                },
                declineOffer: {
                    buyerConfirm: {
                        title: 'Decline?',
                        msg: 'Are you sure you want to decline this offer?',
                    },
                    buyerSuccess: {
                        msg: 'You declined the offer!',
                    },
                    sellerConfirm: {
                        title: 'Decline?',
                        msg: 'Are you sure you want to decline this request?',
                    },
                    sellerSuccess: {
                        msg: 'You declined the sample request!',
                    },
                },
                acceptOffer: {
                    buyerConfirm: {
                        title: 'Accept?',
                        msg: 'Are you sure you want to accept this offer?',
                    },
                    buyerSuccess: {
                        msg: 'You accepted the offer!',
                    },
                },
                sellerInitConfirm: {
                    title: 'Nudge for sample order',
                    msg: 'Do you want to encourage {brandName} to start a sample order?',
                    success: 'Message sent successfully',
                    sendButton: 'Send nudge',
                },
                errors: {
                    sampleSelect: 'Please select at least one sample option!',
                },
            },
            projectSendModal: {
                title: 'Invite manufacturer to project(s)',
                subtitle: 'Select a project(s) to which you wish to invite the chosen manufacturer.',
                multipleProjectsWarning: {
                    title: 'Multiple projects selected',
                    msg: 'When sending invitations for multiple projects, new chat threads (per project) would be created between you and the seller.',
                },
                productsInProject: '{number} products',
                selectAll: 'Select all',
                selectedProject: 'project selected',
                selectedProjects: '{number} projects selected',
            },
            verificationAlert: {
                title: 'Congratulations',
                message: 'Your e-mail has been verified!',
            },
            comingSoonAlert: {
                title: 'Coming Soon',
                message: 'We\'re working hard to implement new features',
            },
            dailyDemoNotice: {
                title: 'Get Your Circularity Guidance',
                description: 'Book a call with one of the experts on our team to get support with your next project!',
                shortDescription: 'Book a call with one of the experts on our team to get support with your next project!',
                btnLabel: 'Schedule call',
            },
        },
        pages: {
            admin: {
                alert: {
                    translations: {
                        title: 'Sure?',
                        message: 'Are you sure you want to run static translations?',
                    },
                    deleteCompany: {
                        title: 'Sure?',
                        message: 'Are you sure you want to soft-delete this company?',
                    },
                },
                additionalCreditsSection: {
                    header: 'Give Credits',
                },
                commentSection: {
                    accepted: 'Accepted',
                    comment: 'Comment',
                    date: 'Date',
                    reviewer: 'Reviewer',
                },
                translations: {
                    update: 'Create or update static translations for:',
                    translateBtn: 'Translate statics',
                },
                firstName: {
                    header: 'First Name',
                    placeholder: 'First Name',
                },
                name: {
                    header: 'Name',
                },
                lastName: {
                    header: 'Last Name',
                    placeholder: 'Last Name',
                },
                companyName: {
                    header: 'Company Name',
                    placeholder: 'Company Name',
                },
                createdAt: {
                    header: 'Created At',
                },
                deliveryDate: {
                    header: 'Delivery Date',
                },
                country: {
                    header: 'Country',
                    placeholder: 'Country',
                },
                city: {
                    header: 'City',
                    placeholder: 'City',
                },
                email: {
                    header: 'Email',
                    placeholder: 'Email',
                },
                status: {
                    header: 'Status',
                },
                sellerKycStatus: {
                    header: 'KYC Status',
                    status: {
                        started_by_admin: 'Started by admin',
                        active: 'Completed (verification successful)',
                        inactive: 'Inactive',
                        blocked: 'Failed (denied by Online Payment Platform)',
                        pending: 'Waiting for verification from Online Payment Platform',
                        unverified: 'Waiting for more details from manufacturer',
                        more_info_needed: 'More information needed from manufacturer',
                        incomplete: 'Incomplete',
                        not_started: 'Not started',
                    },
                },
                phoneNumber: 'Phone number',
                employees: 'employee(s)',
                sampleOrders: {
                    filterResults: 'sample order(s)',
                    productionCosts: 'Production Costs',
                    shippingCosts: 'Shipping Costs',
                    deliveryType: 'Delivery Type',
                    buyer: {
                        label: 'Buyer',
                        placeholder: 'Buyer Name',
                    },
                    seller: {
                        label: 'Seller',
                        placeholder: 'Seller Name',
                    },
                    type: {
                        digital: 'Digital',
                        physical: 'Physical',
                    },
                    status: {
                        products: 'Products',
                        shipping_details: 'Shipping details',
                        cost_estimation: 'Cost estimation',
                        completed: 'Completed',
                        buyer_cancelled: 'Buyer cancelled',
                        seller_cancelled: 'Seller cancelled',
                        buyer_declined: 'Buyer declined',
                        seller_declined: 'Seller declined',
                        accepted: 'Accepted',
                    },
                },
                purchaseOrders: {
                    subTotalCosts: {
                        label: 'Sub Total Cost',
                    },
                    totalCosts: {
                        label: 'Total Costs',
                    },
                    product: {
                        label: 'Product',
                    },
                    completeEscrow: {
                        title: 'Complete escrow',
                        message: 'Are you sure you want complete the escrow? The seller will receive the amount on his bank account.',
                        completed: 'Transaction completed!',
                    },
                },
                orderType: {
                    header: 'Order type',
                    type: {
                        purchase_order: 'Purchase Order',
                        sample_order: 'Sample Order',
                    },
                },
                orderStatus: {
                    inProduction: 'In production',
                    shipping: 'Shipping',
                },
                orderNumber: 'Order Number',
                legalDetails: 'Legal Details',
                manufyValueApplication: {
                    alert: {
                        application: {
                            accept: {
                                title: 'Accept application?',
                                message: 'Are you sure you want to accept this application?',
                            },
                            decline: {
                                title: 'Decline application?',
                                message: 'Are you sure you want to decline this application?',
                            },
                            delete: {
                                title: 'Delete application?',
                                message: 'Are you sure you want to delete this application?',
                            },
                        },
                    },
                },
                catalogProducts: {
                    title: 'Catalog Products',
                },
                productsOffers: {
                    title: 'Product Offers',
                },
                createAdmin: {
                    title: 'New Admin',
                },
                subscriptions: {
                    title: 'Subscriptions'
                },
                onboardingPages: {
                    company_name: 'Company name',
                    company_phone: 'Company phone',
                    company_type: 'Company type',
                    seller_services: 'Seller services',
                    buyer_objective: 'Buyer objective',
                    company_categories: 'Company categories',
                    buyer_services: 'Buyer services',
                    seller_capacity: 'Seller capacity',
                    company_address: 'Company address',
                    company_socials: 'Company socials',
                    company_turnover: 'Company turnover',
                    company_employees: 'Company employees',
                    seller_connections: 'Seller connections',
                    seller_appointment: 'Seller appointment',
                    company_subscription: 'Company subscription',
                    summary: 'Summary',
                    completed: 'Completed',
                },
                pricingTables: {
                    title: 'Pricing Tables',
                },
            },
            dashboard: {
                title: 'Dashboard',
                newUpdates: '{amount} new updates',
            },
            homePage: {
                title: 'Good to see you again!',
                description: 'Enter your credentials to continue managing your circular projects',
                rfqButton: 'Start your production',
                noAccount: {
                    title: 'Don\'t have an account yet?',
                    btnLabel: 'Register now',
                },
            },
            chat: {
                title: 'Messages',
                lastSeen: 'Last seen: {varDate}',
                today: 'today',
                online: 'Online',
                messagePlaceholder: 'Type here..',
                showOriginalBtn: 'Translated',
                showTranslationBtn: 'Original',
                originalTextsLabel: 'Showing original text',
                translatedTextsLabel: 'Auto-translated to {varLocale}',
                counterOfferBtn: 'Counter offer',
                startOrderBtn: 'Start Order',
                loadMoreBtn: 'Load older messages',
                userTyping: '{varName} is typing...',
                reply: 'Reply',
                replyingTo: 'Replying to',
                attachment: 'attachment',
                attachments: 'Attachments',
                uploadFiles: 'Upload files',
                sharedAttachments: 'Shared attachments',
                otherAttachments: 'Other attachments',
                notSubscribedDisclaimer: 'The company you are trying to reach does not have an active subscription',
                chatOptions: 'Chat options',
                searchbar: {
                    placeholder: 'Search',
                },
                tabs: {
                    about: 'About',
                    products: 'Products',
                    services: 'Services',
                    orders: 'Orders',
                },
                browseProjects: {
                    title: 'Browse through your active projects',
                    text: 'Manufy facilitates you with the best manufacturers and their best offers',
                },
                detailsPanel: {
                    co2Compensate: 'Compensate your CO2-emissions for free',
                    currentOffer: 'current offer',
                },
                sampleOrder: {
                    start: 'Start Sampling',
                    view: 'View sample order status',
                },
                purchaseOrder: {
                    start: 'Start Order',
                    view: 'View order status',
                    waitingForCosts: 'Indicate costs',
                    sellerWaitingForPayment: 'View payment status',
                    waitingForShipmentSent: 'Fill in shipment details',
                    waitingForShipmentReceived: 'Confirm shipment',
                    disputeStarted: 'Problem solved',
                    leaveReview: 'Leave a review',
                    initiated: 'Start order',
                    showReview: 'View review',
                },
                interactive: {
                    chat_disclaimer: {
                        buyer: {
                            title: 'Attention',
                            body: 'Please keep communication with service providers on Manufy at all times. If you communicate outside of the platform we can’t provide the following perks:',
                            list: [
                                {__icon: 'shield-halved', label: 'Secure communication between parties',},
                                {__icon: 'user-shield', label: 'Mediate in case of a dispute',},
                                {__icon: 'leaf', label: 'Give advice on the services offered',},
                            ],
                        },
                        seller: {
                            title: 'Attention',
                            body: 'Please keep all communication and deals within the platform to ensure fair business. Only then, the Manufy team can support you with your business.',
                        },
                        admin: {
                            title: '',
                            body: '',
                            list: [],
                        },
                        general: {
                            title: '',
                            body: 'Please keep communication and deals with Brands on Manufy at all times. If you communicate outside of the platform we might be inclined to take the following steps:',
                            list: [],
                        },
                    },
                    inquiry_from_buyer: {
                        buyer: {
                            title: 'Product Inquiry',
                            body: 'You started an inquiry for:',
                        },
                        seller: {
                            title: 'Product Inquiry',
                            body: '{varName} started an inquiry for:',
                        },
                        admin: {
                            title: '',
                            body: '',
                        },
                        general: {
                            title: '',
                            body: 'A new inquiry was started',
                            list: [],
                        },
                    },
                    video_call: 'Video meeting',
                    sample_order_started: '{varName} has requested samples',
                    sample_order_initiated: '{varName} wants to send you samples',
                    sample_order_costs_updated: '{varName} has made a cost estimation',
                    sample_order_accepted: '{varName} has accepted the sample order',
                    sample_order_buyer_declined: '{varName} has declined the sample order',
                    sample_order_seller_declined: '{varName} has declined the sample order',
                    sample_order_buyer_cancelled: '{varName} has cancelled the sample order',
                    sample_order_seller_cancelled: '{varName} has cancelled the sample order',
                    purchase_order_initiated: '{varName} wants you to start a purchase order',
                    purchase_order_started: '{varName} initiated an order. Please specify the costs for this order.',
                    purchase_order_costs_updated: '{varName} has updated the costs of the order. Please review the offer and either accept or decline it using the buttons below.',
                    purchase_order_buyer_cancelled: '{varName} cancelled the order',
                    purchase_order_seller_cancelled: '{varName} cancelled the order',
                    purchase_order_buyer_declined: '{varName} declined the order',
                    purchase_order_seller_declined: '{varName} declined the order',
                    purchase_order_waiting_for_payment: 'Payment request',
                    purchase_order_seller_waiting_for_payment: 'A payment request has been sent to the buyer. We will notify you once the payment has been completed.',
                    purchase_order_waiting_for_money_received: 'Let the brand know when you have received the bank transfer by confirming the transaction.',
                    purchase_order_waiting_for_shipment_sent: 'Let {varName} know when the order is shipped. Indicate the details via the button below.',
                    purchase_order_waiting_for_shipment_received: '{varName} shipped the order. See track & trace details. Please confirm when the shipment has arrived via the button below.',
                    purchase_order_waiting_for_invoice: 'Please upload the invoice of the order.',
                    purchase_order_waiting_for_money_release: 'REMINDER - Confirmation needed. Please check if you received the order and that it meets what you agreed upon with the manufacturer. Confirm via the button below',
                    purchase_order_dispute_started: 'You reported an issue with the order. When the issue is resolved, please indicate this via the button below.',
                    purchase_order_waiting_for_review: 'Let us know how you feel about this deal! Please don’t forget to give feedback to the manufacturer.',
                    purchase_order_review_placed: '{varName} placed a review.',
                    purchase_order_document_uploaded: '{varName} uploaded new document(s) to the order',
                    video_call_joined: '{varName} entered the video meeting',
                    waiting_for_money_received: 'Waiting for manual bank transfer',
                },
                system: {
                    offer_sent: '{varName} has sent an offer',
                    offer_updated: '{varName} has updated the offer',
                    offer_from_buyer: '{varName} shared a project',
                    chat_mail_phone_warning: 'Please communicate via this secured chat so we can act as mediator in any unexpected disputes. We cannot take consequences if communication was made on external platforms (i.e e-mail and Whatsapp).',
                    chat_disclaimer: 'Please communicate via this secured chat so we can act as mediator in any unexpected disputes. We cannot take consequences if communication was made on external platforms (i.e e-mail and Whatsapp).',
                    support_chat_disclaimer: 'Welcome to the Manufy Support chat!',
                    sample_order_initiated: '{varName} wants to start sampling',
                    purchase_order_initiated: '{varName} wants to start a purchase order',
                    purchase_order_started: '{varName} started an order',
                    purchase_order_costs_updated: '{varName} updated the costs for the order',
                    purchase_order_accepted: '{varName} accepted the order',
                    purchase_order_buyer_cancelled: '{varName} cancelled the order',
                    purchase_order_seller_cancelled: '{varName} cancelled the order',
                    purchase_order_buyer_declined: '{varName} declined the order',
                    purchase_order_seller_declined: '{varName} declined the order',
                    purchase_order_payment_completed: '{varName} completed the payment',
                    purchase_order_payment_failed: 'Payment failed, please try again or contact our customer support if this problem persists',
                    purchase_order_waiting_for_shipment_received: '{varName} shipped the order',
                    purchase_order_shipment_received: '{varName} indicated the order was completed successfully!',
                    purchase_order_waiting_for_money_received: '{varName} chose bank transfer as payment method',
                    purchase_order_dispute_started: 'Transaction paused. {varName} reported an issue with the order',
                    purchase_order_dispute_closed: 'The issue with the order has been resolved',
                    purchase_order_review_placed: '{varName} placed a review',
                    video_call_left: '{varName} left the video meeting',
                },
                noMessagesCta: {
                    btnLabel: 'Start your project now',
                    subText: 'or browse through our  <a href="{varSellerLink}" class="text-blue-400">manufacturers </a> or their <a href="{varProductLink}" class="text-blue-400">products</a>'
                }
            },
            rfqwizard: {
                stepper: {
                    productDetails: 'Project Details',
                    products: 'Products',
                    services: 'Services',
                    confirmation: 'Confirmation',
                    finished: 'Finished',
                },
                headers: {
                    project: {
                        title: 'Enter your project details',
                        description: 'Add more information to your project to identify the most sustainable manufacturers',
                    },
                    products: {
                        title: 'Add your products',
                        description: 'Add as many products as you like to your project',
                    },
                    service: {
                        description: 'Add as many requests as needed to your project',
                    },
                    overview: {
                        title: 'Confirmation',
                        description: 'Review your project before submitting it',
                    },
                },
                alert: {
                    message: 'For buyers only!',
                    btnA: 'ok',
                },
                project: {
                    name: {
                        label: 'Project Name',
                        placeholder: 'Project name',
                        sub: 'Name your project, so you can easily find it back later',
                    },
                    maxOffers: {
                        label: 'Max. number of quotations to be received',
                        sub: 'Indicate the maximum number of quotations you like to receive. Min { varMin } / max { varMax }',
                    },
                    deliveryDate: {
                        label: 'Delivery Date',
                        sub: 'Select preferred delivery date',
                    },
                    transportMode: {
                        label: 'Mode of Transport',
                        sub: 'Select preferred mode of transport',
                    },
                    countries: {
                        label: 'Sourcing countries',
                        sub: 'Define countries you would like to source from. Adding more countries will lead to more results',
                    },
                    quotations: {
                        label: 'Max Quotations',
                    },
                },
                Request: {
                    deleteAlert: 'Are you sure you want to delete this service request?',
                },
                product: {
                    name: {
                        label: 'Product Name',
                        placeholder: 'Sample Product Name',
                    },
                    targetPrice: {
                        label: 'Target Price',
                        sub: 'Estimate price per piece',
                    },
                    targetQuantity: {
                        label: 'Target Quantity',
                    },
                    totalPrice: {
                        label: 'Value',
                        sub: 'Total product value',
                    },
                    quantity: {
                        label: 'Quantity',
                    },
                    productSpecifications: {
                        label: 'Product Specifications',
                    },
                    attachments: {
                        label: 'Attachments',
                    },
                    category: {
                        label: 'Category',
                    },
                    description: {
                        label: 'Specifications',
                        placeholder: 'Your product specifications..',
                    },
                    techpack: {
                        label: 'Techpack',
                        sub: 'E.g. technical drawings, size specs, CAD designs. (<a href="{varExampleUrl}" target="_blank" class="text-blue-400"><u>Download example</u></a>)',
                        status: {
                            tpYes: 'I have a technical drawing / tech pack',
                            tpAssistance: 'I require assistance with my technical drawing',
                            tpNo: 'I will upload the technical drawing later',
                        },
                        tpYesSub: 'The technical drawing is visible for manufacturers after submitting this project.',
                        tpAssistanceSub: 'When you finish making/editing this project, a new page will open with more info. (or you can check out the <a href="{varAssistanceUrl}" target="_blank" class="text-blue-400"><u>link</u></a> here now)',
                        tpNoSub: 'You can upload a techpack later but your project won\'t be shown to manufacturers until you do.',
                        placeholder: 'Select files..',
                    },
                    deleteAlert: 'Are you sure you want to delete this product?',
                },
                overview: {
                    recipients: 'Quotation Recipients',
                },
                perks: {
                    title: 'Manufy Perks',
                    description: 'Doing business on Manufy gives you the following perks:',
                    buyerProtection: 'Buyer protection & secure payments: Your money is only transferred to manufacturer once you’ve received your goods',
                    emissionCompensation: 'Free CO2 compensation on all orders',
                    growth: 'Keeping business on the platform helps Manufy improve and grow',
                },
                finished: {
                    title: 'Thank you! Your request has been posted.',
                    body: 'Your request is now visible on your dashboard. As long as there are no bids on your request you can change it in your dashboard.',
                    newRfqBtnLabel: 'New request',
                    toDashboardBtnLabel: 'To dashboard',
                    successLabel: 'RFQ Posted',
                }
            },
            reviewsListPage: {
                description: 'Here you can find reviews of completed orders.',
                unreviewedOrders: {
                    title: 'Unreviewed Orders',
                    noOrdersYet: 'You don\'t have orders waiting for review yet',
                },
                reviews: {
                    reviewedAt: 'Reviewed At',
                    rating: 'Rating',
                    noReviewsYet: 'You haven\'t reviewed any orders yet'
                },
            },
            buyerDashboard: {
                headline: 'Recommended Manufacturers',
                activeProjects: 'Active Projects',
                unreadMessages: '{count} Unread Messages',
                pendingOrders: 'Pending Orders',
                startOrder: {
                    title: 'Place your first order',
                    description: 'Place your first order and...',
                    btnLabel: 'Start order',
                    titleAlternative: 'Place your next order',
                },
                latestMessages: 'Latest messages',
                noMessages: 'No messages found',
                moreProjects: '{count} more...',
                getStarted: 'Get started by creating a new project!',
                stats: {
                    projects: 'Projects',
                    orders: 'Orders',
                    lastSeen: 'Last seen',
                    registeredSince: 'With us since',
                },
                searchSection: {
                    existingUserTitle: 'Are you looking for something new?',
                    newUserTitle: 'What are you looking for today?',
                    description: 'Browse through our database of verified sustainable solution partners and their services',
                },
                welcome: {
                    title: 'Welcome back, {name}!',
                    subtitle: 'Manage Your Circular Projects in One Place',
                    description: 'Choose a project below to monitor your circularity progress, track the profit generated from your circular solutions, and see your yearly Co2 savings. Want to learn how these solutions can benefit your business? Book a call with our circularity experts!',
                },
                strategies: {
                    title: 'From Idea to Impact: Explore Manufy’s Circular Strategies',
                    description: 'Manufy offers a wide range of circular strategies to help your business grow sustainably. Click on each strategy to learn more how Manufy can help you create a business case, our comprehensive impact models, and see which partners can help you bring it to life,',
                },
                sourcing: {
                    title: 'Manufy Sourcing',
                    description: 'Are you looking for your next sourcing partner? Click here to join our sourcing platform, where you can place a sourcing request or browse our network.',
                },
                pendingRfq: {
                    title: 'Pending RFQ',
                    message: 'You have a pending RFQ. Want to finish it now?',
                    btnA: 'Finish',
                    btnB: 'Later',
                },
                profileScore: {
                    incentive: 'Brands with a complete profile have an 80% higher chance of finding a suitable manufacturer',
                    title: 'Trust Score',
                    completed: 'completed',
                    writeBriefIntroduction: 'Write a brief company introduction',
                    addVatNumber: 'Add a validated VAT number',
                    addCompanyLogo: 'Add a company logo',
                    addPhoneNumber: 'Add phone number',
                    addCompanyWebsite: 'Add company website',
                    addCompanyLocation: 'Add company location',
                    startRFQ: 'Create your first project',
                    startSampleOrder: 'Start a sample order',
                    startPurchaseOrder: 'Start a purchase order',
                    verifyEmail: 'Verify your email',
                },
                unverifiedWarning: {
                    title: 'Get verified',
                    message: 'You’re only a few steps away from exploring the sustainable initiatives our network has to offer. Schedule a call or get in touch with our onboarding specialist!',
                    btnALabel: 'Schedule call',
                    btnBLabel: 'Contact us',
                },
                openToWork: 'Actively looking for business opportunities',
                quickLinks: {
                    company: {
                        title: 'Company',
                        profile: 'Profile',
                        emailPreferences: 'Email Preferences',
                        contactDetails: 'Contact Details',
                        locations: 'Locations',
                        invoices: 'Invoices',
                        manageTeam: 'Manage Team',
                        contacts: 'Contacts',
                        howItWorks: 'How Manufy Works',
                    },
                    services: {
                        title: 'Services',
                        createTechPack: 'Create a Tech Pack',
                        packaging: 'Packaging',
                        impactAnalysis: 'Impact analysis',
                        sustainabilityConsult: 'Sustainability consult',
                    },
                },
                projects: {
                    header: 'Projects',
                    title: 'My Projects',
                    description: 'Here you can find all of your projects split into several stages depending on the status of the project.',
                    projectStages: {
                        title: 'Project Stages',
                        all: 'All',
                        active: 'Active',
                        samples: 'Samples',
                        orders: 'Orders',
                        production: 'Production',
                        shipments: 'Shipments',
                        evaluations: 'Evaluations',
                    },
                    project: {
                        noOffers: 'No offers yet',
                        title: 'Project',
                        by: 'by',
                        unitPrice: 'Unit Price',
                        quantity: 'Quantity',
                        deliveryDate: 'Delivery Date',
                    },
                    product: {
                        category: 'Category',
                        techpack: 'Techpack',
                    },
                    confirmDelete: {
                        title: 'Delete project?',
                        msg: 'Are you sure you want to delete this project? This action can not be reverted.',
                        yes: 'Delete',
                        no: 'Cancel',
                    },
                    confirmUnpublish: {
                        title: 'Unpublish project?',
                        msg: 'Are you sure you want to unpublish this project? Unpublished projects are not visible for manufacturers.',
                        yes: 'Unpublish',
                        no: 'Cancel',
                    },
                    confirmPublish: {
                        title: 'Publish project?',
                        msg: 'Are you sure you want to publish this project? The project will be visible for manufacturers.',
                        yes: 'Publish',
                        no: 'Cancel',
                    },
                    filters: {
                        sellerName: 'Manufacturer',
                        projectStatus: 'Project status',
                        unreadMessages: 'Unread messages',
                    },
                },
                projectStatus: {
                    active: 'active',
                    sampling: 'sampling',
                    order: 'order',
                    production: 'production',
                    shipping: 'shipping',
                    closed: 'closed',
                    accepted: 'accepted',
                },
                sellerList: {
                    title: 'Manufacturers',
                    description: 'Find the most suitable European manufacturer',
                },
                productList: {
                    title: 'Products',
                    description: 'Browse through products of our manufacturers',
                },
            },
            sellerDashboard: {
                publicProfileBtn: 'View Profile',
                profileScore: {
                    title: 'Trust Score',
                    completed: 'completed',
                    writeBriefIntroduction: 'Write a brief company introduction',
                    addCompanyLogo: 'Add a company logo',
                    addPhoneNumber: 'Add phone number',
                    addCompanyWebsite: 'Add company website',
                    addCompanyLocation: 'Add company location',
                    placeBid: 'Reply to your first request for quotation',
                    sendSampleNudge: 'Encourage brands to request a sample order',
                    sendPurchaseNudge: 'Encourage brands to request a purchase order',
                },
                projectInvitation: {
                    title: 'New Project Invitation!',
                    body: '{varBuyerName} has invited you to work together on a project. Have a look at this new business opportunity!',
                    btn: 'View project',
                },
                oppVerification: {
                    notStarted: {
                        title: 'Verification Status: Not started',
                        message: 'Our payment provider (Online Payment Platform) needs to verify your information before it is possible to receive payments. Please contact us to start this process.',
                    },
                    incomplete: {
                        title: 'Verification Status: Incomplete',
                        message: 'Our payment provider (Online Payment Platform) is missing information to start your account verification. Please check your account verification to upload missing information.',
                    },
                    pending: {
                        title: 'Verification Status: Pending',
                        message: 'Our payment provider (Online Payment Platform) is verifying your information. This may take up to 3 working days. Please keep track of your verification status on this page. Online Payment Platform might need additional information soon.',
                    },
                    moreInfoNeeded: {
                        title: 'Verification Status: More information needed',
                        message: 'Our payment provider (Online Payment Platform) will require more information to verify your account. Please upload additional information via the Customer Support page of Online Payment Platform.',
                    },
                    active: {
                        title: 'Verification Status: Active',
                        message: 'There is no action required. You are able to receive payments via Manufy.',
                    },
                    btnLabels: {
                        checkVerification: 'See account verification',
                        faq: 'FAQ',
                    },
                },
                filters: {
                    capacities: 'Capacities',
                    title: 'Filters',
                    hide: 'Hide',
                    show: 'Show',
                    name: 'Name',
                    categories: 'Categories',
                    countries: 'Countries',
                    minOrderQty: 'Minimum Order Quantity',
                    quotationWithTp: 'Quotations With Techpacks',
                    hasTechpack: 'Techpack available',
                    isVerified: 'Verified only',
                    isActive: 'Active only',
                    results: 'results',
                    resultsCap: 'Results',
                    manufacturers: 'manufacturers',
                    moqTooltip: 'Minimum order quantity the manufacturer has set for orders',
                    companyType: 'Company Type',
                    fabrics: 'Fabrics',
                },
                quotationWall: {
                    title: 'Quotation wall',
                    description: 'Browse through all available projects',
                    vatWarning: {
                        title: 'ATTENTION',
                        message: 'To be eligible for VAT reverse-charge, your VAT number is required. We check this automatically with the so-called VIES check of the European register. If you do not enter a validated VAT number, Dutch VAT of 21% will be charged on the invoice. You may not be able to reclaim this VAT. If you are an EU company and do not (yet) have a VAT number, you can send us supporting documents proving this.',
                        btnLabel: 'Contact us',
                    },
                    unverifiedWarning: {
                        title: 'Welcome to Manufy!',
                        message: 'You’re only a few steps away from exploring new business. Our Onboarding specialist will reach out to you to get you started. Don’t want to wait? Please send us a message or schedule a call.',
                        btnALabel: 'Schedule call',
                        btnBLabel: 'Chat with us',
                    },
                },
                project: {
                    title: 'Project',
                    by: 'by',
                    posted: 'Posted',
                    updated: 'updated',
                    offersLeft: '{varCount} spots left',
                    quantity: 'Quantity',
                    unitPrice: 'Unit Price',
                    deliveryDate: 'Delivery Date',
                    techpack: 'Techpack',
                    moreToLoad: 'More to load',
                    noMoreResults: 'End of project list',
                    fabrics: 'Requested fabrics',
                    requestedFabricsTooltip: 'You ask manufacturers to source the fabrics|The brand asks manufacturers to source the fabrics|{name} asks manufacturers to source the fabrics',
                    noFabricsTooltip: 'You have not provided information about the necessary fabrics|The brand has not provided information about the necessary fabrics|{name} has not provided information about the necessary fabrics',
                    ownFabricsTooltip: 'You have your own fabric supply|The brand has their own fabric supply|{name} has their own fabric supply',
                },
                projects: {
                    header: 'Projects',
                    title: 'Browse through your projects',
                    description: 'Here you can find all of your projects split into several stages depending on the status of the project.',
                },
                projectStatus: {
                    active: 'active',
                    sampling: 'sampling',
                    closed: 'closed',
                },
                projectStages: {
                    title: 'Project Stages',
                    all: 'All',
                    active: 'Active',
                    samples: 'Sampling',
                    orders: 'Orders',
                    production: 'Production',
                    shipments: 'Shipments',
                    evaluations: 'Evaluations',
                },
                product: {
                    confirmDelete: {
                        title: 'Delete product?',
                        msg: 'Are you sure you want to delete this product?',
                        yes: 'Delete product',
                        no: 'Cancel',
                    },
                },
                service: {
                    confirmDelete: {
                        title: 'Delete service?',
                        msg: 'Are you sure you want to delete this service?',
                        yes: 'Delete service',
                        no: 'Cancel',
                    },
                },
                services: {
                    title: 'Services',
                    noProducts: 'No services',
                    newService: 'New service',
                    editService: 'Edit service',
                    newServiceBtn: 'New service',
                },
                fabrics: {
                    title: 'Fabrics',
                    noFabrics: 'No fabrics',
                    newFabric: 'New fabric',
                    editFabric: 'Edit fabric',
                    newFabricBtn: 'New fabric',
                },
                noResults: 'We couldn\'t find any suitable RFQ\'s',
                products: {
                    title: 'Products',
                    description: 'Manage your product catalogue',
                    newProduct: 'New Product',
                    editProduct: 'Edit Product',
                    pcs: 'pcs',
                    images: 'Images',
                    imageSupport: 'Uploaded images should comply with our guidelines. <a href="{varUrl}" target="_blank" class="text-blue-400">Click here to read more</a>.',
                    fabrics: {
                        title: 'Fabrics',
                        addButton: '+ Add fabric',
                        error: 'Please add at least 1 fabric.',
                        weight: 'Fabric Weight',
                        dontHave: 'I need help sourcing fabrics',
                        haveOwn: 'I have my own fabrics',
                        subText: 'Let the manufacturer know if you have the fabrics for your product.',
                        percentageError: 'Fabric percentages can\'t add up to more than 100%',
                    },
                    pricingLines: {
                        title: 'Pricing',
                        addButton: 'New pricing line',
                        error: 'Please add at least 1 pricing line.',
                        description: 'You can use the pricing lines to show your price per unit threshold for different MOQ.',
                        placeholder: 'Please fill in pricing',
                        moqDuplicateError: 'You can not have duplicate MOQ in your pricing lines!'
                    },
                    features: {
                        title: 'Features',
                    },
                    headers: {
                        name: 'Name',
                        category: 'Category',
                        description: 'Description',
                        madeFor: 'Made For',
                        moq: 'MOQ',
                    },
                    noProducts: 'No products',
                },
            },
            company: {
                title: 'Company',
                home: 'My company',
                profile: {
                    label: 'My profile',
                    title: 'Public Profile',
                    name: {
                        label: 'Company Name',
                        placeholder: 'Your company name',
                    },
                    avatar: {
                        label: 'Logo',
                    },
                    introductionBrief: {
                        label: 'Brief Introduction',
                        placeholder: 'Brief intro of your company',
                        sub: 'Write a short story about the mission and vision of the company. ({varCount}/{varMax})',
                    },
                    introductionDetailed: {
                        label: 'Detailed Introduction',
                        placeholder: 'Detailed intro of your company',
                        sub: 'Write a story about your subjective goals, future and further plans for the company ( {varCount} / {varMax} ).',
                    },
                    urlLinkedin: {
                        label: 'Linkedin',
                        placeholder: 'https://linkedin.com/company-name',
                    },
                    urlFacebook: {
                        label: 'Facebook',
                        placeholder: 'https://facebook.com/company-name',
                    },
                    urlInstagram: {
                        label: 'Instagram',
                        placeholder: 'https://instagram.com/company-name',
                    },
                    urlYoutube: {
                        label: 'Youtube',
                        placeholder: 'https://youtube.com/company-name',
                    },
                    urlPinterest: {
                        label: 'Pinterest',
                        placeholder: 'https://pinterest.com/company-name',
                    },
                    manufyValues: {
                        description: 'Apply now for your Manufy Values. These will show on your company profile once verified!',
                        sections: {
                            available: 'Available Manufy Values',
                            waiting_for_document: 'Applications that need your attention',
                            waiting_for_review: 'Applications under review',
                            verified: 'Approved applications',
                        },
                        name: 'Name',
                        supportComment: 'Support Comment',
                        status: {
                            waiting_for_document: 'Needs action',
                            waiting_for_review: 'Waiting for review',
                            verified: 'Verified',
                        },
                        appliedAt: 'Applied At',
                        updatedAt: 'Updated At',
                    },
                },
                yearlyRevenue: {
                    label: 'Yearly Revenue Range',
                },
                publicProfile: {
                    title: 'Public Profile',
                },
                categories: {
                    label: 'Categories',
                },
                media: {
                    title: 'Photos',
                    profileImages: {
                        label: 'Profile images',
                    },
                },
                contact: {
                    title: 'Contact Details',
                    phone: {
                        label: 'Phone',
                        placeholder: '612345678',
                    },
                },
                sustainability: {
                    title: 'Sustainability',
                    policy: {
                        label: 'Sustainability Policy',
                        sub: 'Write about your policy here. ({varCount}/{varMax})',
                    },
                    factorySize: {
                        label: 'Factory Size in m²',
                    },
                    yearlyPowerConsumption: {
                        label: 'Yearly Power Consumption in kW/h',
                    },
                    renewableEnergyPercentage: {
                        label: 'Renewable Energy Percentage',
                    },
                    energyReductionPolicy: {
                        label: 'Energy Reduction Policy',
                        sub: 'Write about your energy reduction projects here. ({varCount}/{varMax})',
                    },
                    fabricWastePolicy: {
                        label: 'Fabric Waste Policy',
                        sub: 'Write about your fabric waste policy here. ({varCount}/{varMax})',
                    },
                },
                locations: {
                    title: 'Locations',
                    description: 'Here you can find all of your company locations. Add and edit them as you please.',
                    addNewBtn: 'Add Location',
                    name: {
                        label: 'Location Name',
                        placeholder: '',
                    },
                    list: {
                        name: 'Name',
                        address: 'Address',
                        zipCode: 'Zipcode',
                        city: 'City',
                        country: 'Country',
                    }
                },
                location: {
                    titleNew: 'New Location',
                    titleEdit: 'Edit Location',
                },
                invoices: {
                    title: 'Invoices',
                    list: {
                        date: 'Date',
                        number: 'Invoice',
                        amount: 'Amount',
                        vat: 'VAT',
                    }
                },
                orders: {
                    title: 'Orders',
                    description: 'Here you can find all your orders.',
                    noOrders: 'You haven\'t started any orders yet',
                    filters: {
                        companyName: 'Company name',
                        orderNumber: 'Order number',
                        orderStatus: 'Order status',
                    },
                },
                notifications: {
                    title: 'Email preferences',
                    name: {
                        label: 'Email preferences',
                    },
                    channels: {
                        billing: 'Billing',
                        business_opportunities: 'Business Opportunities',
                        chat_messages: 'Chat messages',
                        order_confirmation: 'Order confirmations',
                        tips_and_tricks: 'Tips and Tricks',
                    },
                },
                statistics: {
                    title: 'Statistics',
                    description: 'Here you can see statistics about your profile!',
                    views: 'Profile views',
                    periodAmount: 'Past {number} days',
                    periodType: {
                        daily: 'Daily',
                        weekly: 'Weekly',
                        monthly: 'Monthly'
                    },
                    lineChart: {
                        title: 'Profile views',
                        subTitle: 'Amount of profile views in the period you have selected',
                    },
                    doughnutChart: {
                        title: 'Profile views per country',
                        subTitle: 'Amount of profile views per country in the period you have selected',
                    },
                    barChart: {
                        title: 'Profile views',
                        subTitle: 'Amount of profile views in the period you have selected',
                    },
                },
                users: {
                    title: 'Users',
                    description: 'Here you can find all of your company personal. Add and remove users as you please.',
                    invite: 'Invite',
                    addNewBtn: 'Invite User',
                    list: {
                        name: 'Name',
                        email: 'Email',

                    },
                    openInvitations: {
                        title: 'Open invitations',
                        name: 'Name',
                        email: 'Email',
                        noResults: 'No results found.',
                    },
                    deleteInvitationConfirmation: {
                        msg: 'Are you sure you want to delete this invitation?',
                        title: 'Delete invitation',
                        confirmation: 'Invitation deleted successfully!',
                    },
                    inviteForm: {
                        name: {
                            title: 'Name',
                            placeholder: 'name',
                        },
                        email: {
                            title: 'Email',
                            placeholder: 'email',
                        },
                        subject: {
                            title: 'Subject',
                            placeholder: 'Join Manufy!'
                        },
                        message: {
                            title: 'Message',
                            placeholder: 'Hey, I\'d like to invite you to Manufy!'
                        },
                    },
                },
                manageTeam: {
                    title: 'Manage Team'
                },
                customerService: {
                    title: 'Customer Service'
                },
                sustainabilityConsult: {
                    title: 'Sustainability Consult'
                }
            },
            credits: {
                title: 'Credits',
                text: {
                    part1: 'Brands are joining Manufy to find the right sustainable manufacturer to get their production process started. With new projects added daily, we want to make sure that you are not missing out on the action.',
                    part2: 'Our data shows that it takes an average of 10 credits for manufacturers to land a project with a new brand. That is why we  created various packages with attractive discounts to get you started.',
                    part3: 'Your new production job is around the corner, so why wait?',
                },
                balance: 'Balance',
                credits: 'credits',
                vatNumber: 'VAT number',
                buyBtnLabel: 'Buy {varProductLabel}',
                exVat: 'excl. VAT',
                products: {
                    credits_10: '10 Credits',
                    credits_10_2: '10 Credits',
                    credits_20: '20 Credits',
                    credits_50: '50 Credits',
                    credits_50_2: '50 Credits',
                    credits_100: '100 Credits',
                },
                openAlert: {
                    message: 'Transaction pending',
                },
                successAlert: {
                    message: 'Transaction successfull',
                },
                failAlert: {
                    message: 'Transaction failed',
                },
                errors: {
                    vat: 'Invalid VAT number',
                },
            },
            account: {
                title: 'Account',
                team: {
                    title: 'Team',
                },
                credits: {
                    title: 'Credits',
                },
            },
            sellerFilters: {
                title: 'Preferences',
                description: 'These settings affect which email notifications you receive and the which projects are visible on your quotation wall',
            },
            userDetails: {
                title: 'My Account',
                email: 'E-mail',
                firstName: 'First name',
                lastName: 'Last name',
                language: 'Language',
                password: 'Password',
                passwordConfirm: 'Repeat password',
                errors: {
                    password: 'Your password needs to be at least 8 characters and contain 1 letter and 1 number or special character',
                    passwordConfirm: 'Your passwords don\'t match',
                },
                changeEmail: {
                    title: 'Change Email',
                    description: 'Update the login email for your account',
                    descriptionDisabled: 'Your account is linked to Google which makes it impossible to change in our system. Please send a message to customer support if you would like to change your email account.',
                    alert: {
                        title: 'Change email',
                        message: 'Are you sure you want to change your email? You will be logged out and will need to re-verify the new email before you can use your account again. A verification email will be sent to the new email.',
                    },
                    sectionMessage: {
                        title: 'Not verified',
                        message: 'Email has not yet been verified. Please check your email for the verification email or click the button below to send a new one.',
                        buttonA: 'Resend verification email',
                    },
                    newEmail: 'New email',
                },
            },
            verifyMail: {
                breadcrumb: 'Verify Email',
                title: 'Welcome to Manufy!',
                body: 'Please verify your mail first! <b>The verification link is valid for 60 minutes, so be sure to verify before the link expires.</b><br> Haven\'t received a link yet or did the old one expire?',
                resendConfirm: 'A new confirmation link has been send to {varEmail}.',
                resendLinkBtn: 'Resend link',
                onboarding: {
                    title: 'Plan your onboarding call',
                },
            },
            subscriptionPage: {
                buyer_tier_2: {
                    title: 'Unlock the full potential of Manufy',
                    body: 'To create another fashion project or contact our manufacturers directly, you need to upgrade to our <strong class="text-neutral-800">Fashion Brand Package</strong>.',
                    buttonLabel: 'Upgrade your plan',
                    perks: {
                        perk1: {
                            title: 'Create more projects',
                            description: 'Unlock your potential with up to 5 collections each year',
                        },
                        perk2: {
                            title: 'Personalize your experience',
                            description: 'Receive up to 3 hours of 1 on 1 customer support each month',
                        },
                        perk3: {
                            title: 'Secure your brand',
                            description: 'Receive Buyer Protection of up to &euro;1000 on every order',
                        },
                    },
                },
                buyer_tier_3: {
                    title: 'Take fashion sourcing to the next level',
                    body: 'To create another fashion project you need to upgrade to our <strong class="text-neutral-800">Enterprise Package</strong>.',
                    buttonLabel: 'Upgrade your plan',
                    perks: {
                        perk1: {
                            title: 'Unleash your business',
                            description: 'Explore the depths of your creativity with unlimited collections',
                        },
                        perk2: {
                            title: 'Personalize your experience',
                            description: 'Develop a personal sourcing journey with our team',
                        },
                        perk3: {
                            title: 'Dedicated customer support',
                            description: 'Receive unlimited Buyer Protection on every order',
                        },
                    },
                },
                seller_tier_2: {
                    title: 'Unlock the full potential of Manufy',
                    body: 'To respond to more RFQs, receive more RFQs or display more product, you need to upgrade to our <strong class="text-neutral-800">Growth Package</strong>.',
                    buttonLabel: 'Upgrade your plan',
                    perks: {
                        perk1: {
                            title: 'More responses per month',
                            description: 'Unlock your potential with up to 100 responses on RFQs per month',
                        },
                        perk2: {
                            title: 'Display more products',
                            description: 'Display up to 30 products',
                        },
                        perk3: {
                            title: 'Receive more RFQs',
                            description: 'Receive up to 20 direct RFQs',
                        },
                    },
                },
                seller_tier_3: {
                    title: 'Take fashion production to the next level',
                    body: 'To respond to more RFQs, receive more RFQs or display more product, you need to upgrade to our <strong class="text-neutral-800">Professional Package</strong>.',
                    buttonLabel: 'Upgrade your plan',
                    perks: {
                        perk1: {
                            title: 'More responses per month',
                            description: 'Unlock your potential with an unlimited amount of responses on RFQs per month',
                        },
                        perk2: {
                            title: 'Display more products',
                            description: 'Display unlimited products',
                        },
                        perk3: {
                            title: 'Receive more RFQs',
                            description: 'Receive an unlimited amount of direct RFQs',
                        },
                    },
                },
                subtitle: 'Are you ready to take your brand to the next level? Upgrade your plan now!',
            },
            registerCompany: {
                title: 'Welcome to Manufy!',
                header: 'Register company',
                description: {
                    pendingRfq: 'Almost there! We still need some company info before you can submit your RFQ!',
                    noPending: 'Almost there! We still need a bit of company info!',
                },
                pages: {
                    companyType: 'Company type',
                    companyCreate: 'Company information',
                    companyAddress: 'Company address',
                    companyDetails: 'Company details',
                },
                warning: {
                    title: 'Don\'t lose your quotation',
                    message: 'Note that you need a brand account to actually submit your request for quotation (RFQ)!',
                    buttonALabel: 'I am a brand',
                    buttonBLabel: 'View Quotation',
                },
                companyName: {
                    title: 'What\'s your business name?',
                    label: 'Business name',
                    summaryCaption: 'Business name',
                    supportText: 'This is the company name your clients will see',
                    placeholder: 'Enter business name',
                },
                fromMarketingChannel: {
                    title: 'How did you find us?',
                },
                companyChoice: {
                    title: 'Choose your business type',
                    summaryCaption: 'Business type',
                    supportText: `Can\'t find what you are looking for? Ask our contact our <a href="#" onClick="window.Intercom('show')" class="text-blue-400 underline">customer support</a> for help.`,
                    caption: 'Nice to meet you <strong class="text-neutral-400">{name}</strong>!',
                    buyer: {
                        type: 'Fashion Brand',
                        description: 'Looking for apparel manufacturers',
                        perks: {
                            projects: 'Create fashion projects',
                            inquiries: 'Send inquiries to our manufacturers',
                            products: 'Purchase products from our catalogue',
                        },
                    },
                    seller: {
                        type: 'Clothing Manufacturer',
                        description: 'Looking for production opportunities',
                        perks: {
                            inquiries: 'Get access to brand product inquiries',
                            offers: 'Offer prices and services to our brands',
                            products: 'Produce and sell sustainable products',
                        },
                    },
                },
                companyPhase: {
                    title: 'At which stage does your brand fall?',
                    summaryCaption: 'Company phase',
                },
                companyPhone: {
                    label: 'Phone number',
                    title: 'On which phone number can we reach you?',
                    summaryCaption: 'Phone number',
                },
                registrationYear: {
                    label: 'Registration year',
                    title: 'When was your business established?',
                    placeholder: 'Enter the year of establishment',
                    summaryCaption: 'Year of establishment',
                },
                manufacturerType: {
                    title: 'What other services apply to you?',
                    summaryCaption: 'Business services',
                    otherPlaceholder: 'Enter other services you provide',
                },
                registrationReasons: {
                    title: 'What challenges do you hope to overcome with Manufy?',
                    summaryCaption: 'What Manufy should solve',
                    placeholder: 'Explain here what you are looking for',
                },
                categories: {
                    summaryCaption: 'Production categories',
                    placeholder: 'Search by keywords, such as t-shirt, hoodie, etc.',
                    searchLabel: 'Search categories<span class="text-red-400">*</span>',
                    buyer: {
                        title: 'What type of products do you offer?',
                        supportText: 'Let us know which product types your brand is focusing on to improve matchmaking',
                    },
                    seller: {
                        title: 'Choose your production categories',
                        supportText: 'Let your clients know in which field(s) you business specialises by choosing from our collection of production categories',
                    },
                    search: {
                        label: 'Search categories',
                        placeholder: 'Search by keywords, such as t-shirt, hoodie, etc.',
                    },
                    label: 'Production Categories',
                },
                requestedServices: {
                    title: 'What services are you looking for?',
                    summaryCaption: 'Requested services',
                    supportText: 'Select all that apply',
                    otherPlaceholder: 'Enter other services',
                },
                productionCapacity: {
                    title: 'What\'s your MOQ and maximum production capacity?',
                    moq: {
                        label: 'Minimum order quantity',
                        summaryCaption: 'Minimum order quantity',
                        placeholder: '500',
                    },
                    mpc: {
                        label: 'Maximum capacity',
                        summaryCaption: 'Maximum capacity',
                        placeholder: '20000',
                    },
                },
                companyAddress: {
                    title: 'Where\'s your business registered?',
                    summaryCaption: 'Company address',
                },
                socialMedia: {
                    title: 'How can others find you?',
                    summaryCaption: 'Social media',
                    supportText: 'Share your social links with others to improve your visibility',
                    hasSocials: {
                        yes: 'I have social links to share',
                        no: 'I don\'t have social media',
                    },
                    noLinks: 'You need to fill in at least 1 field.',
                    website: {
                        label: 'Website',
                        placeholder: 'www.yoursite.com',
                    },
                    urlLinkedin: {
                        label: 'Linkedin',
                        placeholder: 'https://linkedin.com/business-name',
                    },
                    urlFacebook: {
                        label: 'Facebook',
                        placeholder: 'https://facebook.com/business-name',
                    },
                    urlInstagram: {
                        label: 'Instagram',
                        placeholder: 'https://instagram.com/business-name',
                    },
                    urlYoutube: {
                        label: 'Youtube',
                        placeholder: 'https://youtube.com/business-name',
                    },
                    urlTikTok: {
                        label: 'TikTok',
                        placeholder: 'https://tiktok.com/business-name',
                    },
                    urlPinterest: {
                        label: 'Pinterest',
                        placeholder: 'https://pinterest.com/business-name',
                    },
                },
                companyEmployees: {
                    title: 'How many employees does your business have?',
                    summaryCaption: 'Total employees',
                },
                companyTurnover: {
                    title: 'What was your revenue from last year?',
                    summaryCaption: 'Last year\'s revenue',
                },
                brandConnections: {
                    title: 'What brands have you worked with previously?',
                    summaryCaption: 'What brands have you worked with previously?',
                    label: 'Brand',
                    placeholder: 'Enter brand name',
                    addBrand: 'Add another brand',
                },
                referralProgram: {
                    title: 'Referral program',
                    summaryCaption: 'Referral program',
                    supportText: 'Score up to <strong class="text-purple-300">€250</strong> per manufacturer and <strong class="text-purple-300">€125</strong> per brand that becomes a paying member',
                    label: 'Referral    ',
                    placeholder: 'Enter email addres',
                    addInvitation: 'Add referral',
                },
                companySubscription: {
                    title: 'Choose your pricing plan',
                    summaryCaption: 'Pricing plan',
                    label: 'pricingPlan',
                    test: 'Free <span class="text-purple-300">3 day</span> trial to explore Manufy',
                },
                personalOnboarding: {
                    title: 'Get to know Manufy in just 15 minutes',
                    summaryCaption: 'Get to know Manufy in just 15 minutes',
                    supportText: 'Schedule a personal onboarding call with one of our colleagues to experience how Manufy works and how it can help you',
                    label: 'personalOnboarding',
                },
                summary: {
                    title: 'Confirm your business details',
                    label: 'summary',
                },
                enterpriseRequest: {
                    title: 'Enterprise plan request',
                    supportText: 'Please select a date and time that work best for you',
                    label: 'enterpriseRequest',
                    buttonLabel: 'Send request',
                },
                completed: {
                    label: 'completed',
                },
                companyPosition: {
                    title: 'What best describes your company role?',
                    summaryCaption: 'What best describes your company role?',
                    options: {
                        owner: 'Owner',
                        productDeveloper: 'Product Developer',
                        designer: 'Designer',
                        buyer: 'Buyer',
                        salesExecutive: 'Sales Executive',
                        agent: 'Agent',
                        other: 'Other...',
                    },
                    placeholder: 'Type here...',
                },
                companyInfo: 'Tell us a bit more about your business',
                companyDetails: 'Set your manufacturing preferences',
                registrationReason: {
                    titleBuyer: 'How will you use Manufy?',
                    titleSeller: 'Tell us more about your services',
                    options: {
                        lookingAround: 'Just looking around',
                        finishCollection: 'Finishing my new collection',
                        firstCollection: 'Creating my first collection',
                        checkPrices: 'Checking prices of manufacturers',
                        newIdeaDesigned: 'To have my new idea designed',
                        other: 'Other...',
                        agent: 'I\'m an agent',
                        ownFacility: 'I have my own production facility',
                    },
                    placeholder: 'Fill in your reason for registration',
                    notAllowed: 'Sorry, we don\'t allow agent on our platform.',
                },
                companyLegal: 'Company details',
                companySocials: 'Social media links',
                onboarding: {
                    title: 'Schedule your onboarding call with our customer support. It will only take 30 minutes.',
                    link: 'Plan onboarding call',
                },
                phone: {
                    label: 'Phone number',
                    countryCodePlaceholder: 'Country code',
                    placeholder: 'Enter phone number',
                },
                verificationCode: {
                    label: 'Verification code',
                    placeholder: 'Enter verification code',
                },
                website: {
                    label: 'Website',
                    placeholder: 'www.business-name.com',
                },
                email: {
                    label: 'Business email',
                    placeholder: "user{'@'}business-name.com",
                },
                registrationCountry: {
                    label: 'Registration country',
                },
                legalOwner: {
                    label: 'Legal owner',
                    placeholder: '',
                },
                vatNumber: {
                    label: 'VAT number',
                    placeholder: '',
                },
                cocNumber: {
                    label: 'CoC number',
                    placeholder: '',
                    info: 'The CoC-number is a number you request at the Chamber of Commerce.'
                },
                ibanNumber: {
                    label: 'IBAN number',
                    placeholder: '',
                },
                bicNumber: {
                    label: 'BIC number',
                    placeholder: '',
                },
                companySize: {
                    label: 'Total employees',
                },
                address1: {
                    label: 'Address line 1',
                    placeholder: 'Street name + number',
                },
                address2: {
                    label: 'Address line 2',
                    placeholder: 'Apartment, suite, unit, building, floor, etc.',
                },
                zipcode: {
                    label: 'Zipcode',
                    placeholder: 'Zipcode',
                },
                region: {
                    label: 'Region',
                    placeholder: 'Region',
                },
                city: {
                    label: 'City',
                    placeholder: 'City',
                },
                country: {
                    label: 'Country',
                    placeholder: 'Country',
                },
                confirmLegal: 'I confirm that I am a legal representative of this company and I have the right to act on behalf of this company.',
                agreeTerms: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">Terms & Conditions</a> and <a href="{varUrlPrivacy}" target="_blank" class="text-blue-400">Privacy Policy</a>.',
                agreeTermsSeller: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">General Terms & Conditions for Manufacturers</a>',
                agreeTermsBuyer: 'I agree with the <a href="{varUrlTerms}" target="_blank" class="text-blue-400">General Terms & Conditions for Buyers</a>',
                errors: {
                    phoneNumber: 'Phone number can only contain digits and must be between 5 and 15 characters long.'
                },
            },
            sourcingIndex: {
                menuTitle: 'Sourcing Network',
                pageTitle: 'Manufy Sourcing Network',
                description: 'Explore our sourcing network',
            },
            strategyReadDiscover: {
                title: 'Explore Solutions',
                description: 'Connect below with the different partners that offer support for this strategy',
            },
            serviceReadDiscover: {
                title: 'Other Solutions',
                description: 'Discover the different partners that offer support for similar solutions',
            },
            sellerIndex: {
                menuTitle: 'Explore Network',
                title: 'Explore Network',
                pageTitle: 'Explore Network',
                description: 'Explore our network of circular solution partners',
                location: 'Location',
                registrationYear: 'Registration Year',
                noDescription: 'No company description',
                noImages: 'No images',
                notApplicable: 'N/A',
                about: 'About',
                products: 'Products',
                services: 'Services',
                noInformationAvailable: 'No information available',
                noFinishedOrders: 'This manufacturer has no finished orders yet',
                sustainabilityPolicy: 'Sustainability Policy',
                energyReductionPolicy: 'Energy Reduction',
                fabricWastePolicy: 'Fabric Waste',
                viewWebsiteBtn: 'View Website',
                reviews: 'Reviews',
                reviewFrom: 'Buyer from {countryName}',
                commentFrom: 'Comment from {companyName}',
                orders: 'Orders',
                alert: {
                    title: 'Want to narrow down your options?',
                    msg: 'Sign in to take advantage of our extensive filtering tools to refine your search by <strong class="text-blue-400">production categories, sourcing countries, MOQs</strong>, and beyond.',
                },
                comment: {
                    title: 'Reply',
                    placeholder: 'Write a comment',
                    commentFromManufacturer: 'Comment from manufacturer',
                    alert: {
                        postComment: {
                            title: 'Post comment',
                            message: 'Are you sure you want to post this comment?',
                        },
                        updateComment: {
                            title: 'Update comment',
                            message: 'Are you sure you want to update this comment?',
                        },
                        deleteComment: {
                            title: 'Delete comment',
                            message: 'Are you sure you want to delete this comment?',
                        },
                    },
                },
                noComment: 'No feedback given',
                noSellersFound: 'No manufacturers found matching your search criteria',
            },
            sellerReadPage: {
                sellerName: 'Manufacturer from {countryName}',
            },
            productIndex: {
                menuTitle: 'Product Showcase',
                title: 'Product Showcase',
                description: 'Browse through the product showcase of some of our sustainable manufacturers',
                noProductsFound: 'No products found matching your search criteria',
                showOriginalBtn: 'Show original text',
                showTranslationBtn: 'Translate to {varLocale}',
                manufyValues: 'Manufy Values',
                category: 'Category',
                madeFor: 'Made For',
                moq: 'MOQ',
                priceUnit: 'Price/unit',
                moqTooltip: 'Minimum order quantity the manufacturer has set for this product',
                fabrics: 'Fabrics',
                products: 'products',
                fabric: 'Fabric',
                fabricWeight: 'Fabric Weight',
                features: 'Product Features',
                yearlyRevenueRange: 'Yearly revenue range',
                lastSeen: 'Last seen',
                productDescription: 'Product description',
                featureTypes: {
                    recycled: 'Recycled',
                    digital_print: 'Digital Print',
                    waterproof: 'Waterproof',
                    breathable: 'Breathable',
                    fitness: 'Fitness',
                    dry_fit: 'Dry-fit',
                    anti_fire: 'Anti-fire',
                    anti_bacterial: 'Anti-bacterial',
                },
                inquiry: {
                    title: 'Request info',
                    strategyTitle: 'Chat with the Manufy Team',
                    total: 'Total value',
                    description: 'Complete the form and get in touch with the manufacturer!',
                    targetPrice: 'Price per unit',
                    targetQuantity: 'Target quantity',
                    moq: 'Minimum order quantity of {quantity} pieces',
                    shippingCosts: 'Shipping prices are not included in this calculation',
                    submit: 'Send request',
                    register: '<span class="text-purple-300 hover:text-purple-500 cursor-pointer">Create an account</span> and get in touch with this manufacturer!',
                    registerAsBuyer: 'You are logged in as a manufacturer. <span class="text-purple-300 hover:text-purple-500 cursor-pointer">Create a buyer account</span> and get in touch with this manufacturer!',
                    noSubscription: '<span class="text-purple-300 hover:text-purple-500 cursor-pointer">Activate your subscription </span> to get in touch with the manufacturer!',
                    startSubscriptionBtn: 'Start Subscription',
                },
                perks: {
                    title: 'Buyer Perks',
                    securePayments: 'Secure payments',
                    buyerProtection: 'Built-in Buyer Protection on orders',
                    co2Compensation: 'Free CO<sub>2</sub> compensation'
                },
            },
            serviceIndex: {
                title: 'Circular solutions',
                menuTitle: 'Solutions',
                description: 'Browse through the services of Manufy\'s partners',
                serviceDescription: 'Service description',
                noServicesFound: 'No services found',
            },
            fabricIndex: {
                title: 'Deadstock fabrics',
                menuTitle: 'Browse Fabrics',
                description: 'Browse through the 1300+ deadstock fabrics offered on Manufy',
                fabricDescription: 'Fabric description',
                noFabricsFound: 'No fabrics found',
            },
            legalDetails: {
                title: 'Bank Verification',
                registrationYearSub: 'Year in which the company was registered',
                registrationCountrySub: 'Country in which the company in registered',
                legalOwnerSub: 'Legal owners of the company',
                vatNumberSub: 'Fill in or update your VAT number here.',
                cocNumberSub: 'Chamber of Commerce (CoC) number of the company.',
                ibanNumberSub: 'IBAN number of the company bank account',
                bicNumberSub: 'BIC number of the company bank account',
                opp: {
                    status: 'Verification status',
                    overviewPage: 'See your company verification status on <a href="{varOverviewUrl}" class="text-blue-400">this overview page</a>',
                    requirements: {
                        label: 'Please complete the following verification requirements',
                        bank_account: 'Bank account',
                        contact: 'Contact info',
                        ubo: 'Ultimate Beneficial Owner (UBO)',
                        contact_phonenumber: 'Phone number',
                        coc_extract: 'Company extract',
                        organization_structure: 'Organizational structure',
                    },
                },
            },
            companyShortCard: {
                subscription: {
                    buyer_tier_0: 'Not subscribed',
                    buyer_tier_1: 'Subscribed',
                    buyer_tier_2: 'Subscribed',
                    buyer_tier_3: 'Subscribed',
                    seller_tier_0: 'Not subscribed',
                    seller_tier_1: 'Subscribed',
                    seller_tier_2: 'Subscribed',
                    seller_tier_3: 'Subscribed',
                },
                timeSince: {
                    seconds: 'Just now',
                    minutes: 'A minute ago|{count} minutes ago',
                    hours: 'An hour ago|{count} hours ago',
                    days: 'Yesterday|{count} days ago',
                    weeks: 'Last week|{count} weeks ago',
                    months: 'Last month|{count} months ago',
                    years: 'Last year|{count} years ago',
                },
            },
        },
        meta: {
            buyerAvailabilityStatuses: {
                looking_for_partners: 'Looking for partners',
                not_actively_searching: 'Not actively searching',
                project_in_process: 'Project in process',
            },
            sellerAvailabilityStatuses: {
                full_capacity: 'Unavailable',
                on_holiday: 'On holiday',
                open_capacity: 'Available',
            },
            transportModes: {
                air: 'Air',
                railway: 'Railway',
                road: 'Road',
                water: 'Water',
                'n/a': 'N/A',
            },
            serviceTypes: {
                full_production: 'Full service',
                sampling: 'Samples',
                fabrics: 'Fabric supplier',
                cmt: 'Cut, make & trim (CMT)',
                sustainability_advice: 'Sustainability advice',
                digital_product_passport: 'Digital product passport',
                white_label_blanks: 'Blanks/ white label',
                techpack: 'Tech packs',
                printing: 'Printing',
                embroidery: 'Embroidery',
                agent: 'Agent',
                logistics_shipping: 'Logistics & shipping',
                other: 'Other',
            },
            serviceCategoryTypes: {
                design_development: 'Circular Design Methods',
                sourcing: 'Clean Materials & Sourcing',
                manufacturing: 'Manufacturing',
                packaging_labeling: 'Labeling & Packaging',
                return_reduction: 'Return Reduction',
                logistics_shipping: 'Logistics & Shipping',
                repair: 'Repair Program',
                resale: 'Resale Platform',
                upcycling: 'Upcycling Strategies',
                recycle: 'End of life Recycling',
                traceability: 'Traceability & Transparency Solution',
                reporting: 'Communication & Reporting',
                certification: 'Certification',
            },
            annualTurnovers: {
                '0k': 'No revenue yet',
                '0_100k': '€1 - €100,000',
                '100k_1m': '€100.000 - €1 million',
                '100_200k': '€100,000 - €200,000',
                '200_500k': '€200,000 - €500,000',
                '500_1000k': '€500,000 - €1 million',
                '1_2m': '€1 million - €2 million',
                '1_10m': '€1 million - €10 million',
                '2_5m': '€2 million - €5 million',
                '5_10m': '€5 million - €10 million',
                '10_25m': '€10 million - €25 million',
                '10_100m': '€10 million - €100 million',
                '25_50m': '€25 million - €50 million',
                '50_100m': '€50 million - €100 million',
                '100m': '€100+ million',
            },
            annualRevenue: {
                '0k': 'No revenue yet',
                '0_100k': '€1 - €100,000',
                '100k_1m': '€100.000 - €1 million',
                '1_10m': '€1 million - €10 million',
                '10_100m': '€10 million - €100 million',
                '100m': '€100+ million',
            },
            companyTypes: {
                educational_institution: 'Educational Institution',
                government_agency: 'Government Agency',
                nonprofit: 'Nonprofit',
                partnership: 'Partnership',
                privatly_held: 'Privately Held',
                public_company: 'Public Company',
                self_employed: 'Self-Employed',
                sole_proprietorship: 'Sole Proprietorship',
            },
            incoterms: {
                cfr: 'CFR',
                cif: 'CIF',
                cip: 'CIP',
                cpt: 'CPT',
                dap: 'DAP',
                ddp: 'DDP',
                dpu: 'DPU',
                exw: 'EXW',
                fas: 'FAS',
                fca: 'FCA',
                fob: 'FOB',
            },
            factorySizes: {
                'below_1000': 'below 1,000 square meters',
                '1001_3000': '1,001 - 3,000 square meters',
                '5001_10000': '5,001 - 10,000 square meters',
                '10001_30000': '10,001 - 30,000 square meters',
                '30001_50000': '30,001 - 50,000 square meters',
                '50001_100000': '50,001 - 100,000 square meters',
            },
            totalEmployees: {
                '0_1': '0 – 1',
                '2_10': '2 – 10',
                '11_50': '11 – 50',
                '51_200': '51 – 200',
                '201_500': '201 – 500',
                '501_1000': '501 – 1.000',
                '1001_5000': '1.001 – 5.000',
                '5001_10000': '5.001 – 10.000',
                '10001_': '10.000+',
            },
            marketingChannels: {
                google: 'Google',
                bing: 'Bing',
                linkedin: 'LinkedIn',
                instagram: 'Instagram',
                facebook: 'Facebook',
                conference_fair: 'Conference / Fair',
                news: 'News',
                referral: 'Referral',
                other: 'Other',
            },
            madeFor: {
                male: 'Men',
                female: 'Women',
                unisex: 'Unisex',
                boys: 'Boys',
                girls: 'Girls',
                children: 'Children',
            },
            legislativeStatus: {
                required: 'Required',
                not_yet_required: 'Not yet required',
                partially_required: 'Partially Required',
            },
            fabrics: {
                cashmere: 'Cashmere',
                cotton: 'Cotton',
                denim: 'Denim',
                elastine: 'Elastine',
                leather: 'Leather',
                linen: 'Linen',
                lycra: 'Lycra',
                modal: 'Modal',
                nylon: 'Nylon',
                polyamide: 'Polyamide',
                polyester: 'Polyester',
                silk: 'Silk',
                spandex: 'Spandex',
                synthetic_leather: 'Synthetic Leather',
                tencel: 'Tencel',
                vegan_leather: 'Vegan Leather',
                viscose: 'Viscose',
                wool: 'Wool',
            },
            grammageType: {
                g_m2: 'g/m²',
                oz: 'oz',
            },
            dimensions: {
                cm: 'cm',
                m: 'm',
            },
            productFeatures: {
                recycled: 'Recycled',
                digital_print: 'Digital Print',
                waterproof: 'Waterproof',
                breathable: 'Breathable',
                fitness: 'Fitness',
                dry_fit: 'Dry-fit',
                anti_fire: 'Anti-fire',
                anti_bacterial: 'Anti-bacterial',
            },
            productStatus: {
                published: 'Published',
                unpublished: 'Unpublished',
            },
            collaborationStatus: {
                active: 'Active',
                sampling: 'Sampling',
                order: 'Order',
                production: 'Production',
                shipping: 'Shipping',
                closed: 'Closed',
            },
            orderStatus: {
                waiting_for_cost_estimation: 'Waiting for cost estimation',
                price_negotiation: 'Price negotiation',
                waiting_for_payment: 'Waiting for payment',
                waiting_for_kyc: 'Waiting for KYC',
                waiting_for_shipment: 'Waiting for shipment',
                waiting_for_money_release: 'Waiting for money release',
                completed: 'Completed',
                buyer_cancelled: 'Buyer cancelled',
                seller_cancelled: 'Seller cancelled',
                buyer_declined: 'Buyer declined',
                seller_declined: 'Seller declined',
            },
            categories: {
                'active_sportswear': 'Active / Sportswear',
                'dresses_skirts': 'Dresses / Skirts',
                'bridal_wear': 'Bridal wear',
                'headwear': 'Headwear',
                'hoodies_sweatshirts': 'Hoodies / Sweatshirts',
                'jackets_coats': 'Jackets / Coats',
                'jeans_denim_shorts': 'Jeans / Denim / Shorts',
                'jumpers_cardigans': 'Jumpers / Cardigans',
                'jumpsuits_playsuits': 'Jumpsuits / Playsuits',
                'baby_wear': 'Baby wear',
                'knitwear': 'Knitwear',
                'lingerie': 'Lingerie',
                'loungewear_nightwear': 'Loungewear / Nightwear',
                'pants': 'Pants',
                'scarfs': 'Scarfs',
                'towels': 'Towels',
                'shirts_blouses': 'Shirts / Blouses',
                'socks_tights': 'Socks / Tights',
                'suits_seperates': 'Suits / Separates',
                'swimwear_beachwear': 'Swimwear / Beachwear',
                'tops': 'Tops',
                'tracksuits_joggers': 'Tracksuits / Joggers',
                't_shirts_singlets_poloshirts': 'T-Shirts / Singlets / Poloshirts',
                'trousers_chinos_shorts': 'Trousers / Chinos / Shorts',
                'underwear': 'Underwear',
                'workwear': 'Workwear',
                'boots': 'Boots',
                'shoes': 'Shoes',
                'sandals_slides': 'Sandals / Slides',
                'trainers_casualshoes': 'Trainers / Casual shoes',
                'audio_tech_accessoires': 'Audio- / Tech accessoires',
                'bags': 'Bags',
                'belts': 'Belts',
                'caps_hats': 'Caps / Hats',
                'gloves_mittens': 'Gloves / Mittens',
                'jewelry': 'Jewelry',
                'glasses_sunglasses': 'Sunglasses / Glasses',
                'ties': 'Ties',
                'technical': 'Technical',
                'wallets': 'Wallets',
                'watches_timepieces': 'Watches / Timepieces',
                'earmuffs': 'Earmuffs',
                'embroidery': 'Embroidery',
                'embroidery_lace': 'Embroidery lace',
                'fabrics_textiles': 'Fabrics / Textiles',
                'clothing_accessories': 'Clothing accessories',
                'dyeing': 'Dyeing',
                'textile_prints_transfers': 'Textile prints / Textile transfers',
                'buckles_metal_ornaments': 'Buckles / Metal ornaments',
                'buttons': 'Buttons',
                'laces': 'Laces',
                'patches': 'Patches',
                'trims': 'Trims',
                'zippers': 'Zippers',
                'leather': 'Leather',
                'chiffon': 'Chiffon',
                'corduroy': 'Corduroy',
                'denim': 'Denim',
                'felt': 'Felt',
                'fleece': 'Fleece',
                'foulard': 'Foulard',
                'khaki': 'Khaki',
                'muslin': 'Muslin',
                'poplin': 'Poplin',
                'sateen': 'Sateen',
                'terrycloth': 'Terrycloth',
                'velvet': 'Velvet',
                'bedding': 'Bedding',
                'table_linen': 'Table linen',
                'blankets': 'Blankets',
                'carpets_rugs': 'Carpets / Rugs',
                'cushions': 'Cushions',
                'curtains': 'Curtains',
                'pet_wear': 'Pet wear',
                'other': 'Other',
            },
            manufyValues: {
                bamboo: 'Bamboo',
                carbon_offsetting: 'Carbon offsetting',
                eco_packaging: 'Eco packaging',
                ethical_labor: 'Ethical labor',
                hemp: 'Hemp',
                low_water_usage: 'Low water usage',
                made_in_europe: 'Made in Europe',
                made_to_order: 'Made to order',
                no_chemicals_used: 'No chemicals used',
                organic_cotton: 'Organic cotton',
                product_passport: 'Product passport',
                recycled_fabrics: 'Recycled fabrics',
                renewable_energy_usage: 'Renewable energy usage',
                transparent_supply_chain: 'Transparent supply chain',
                upcycled: 'Upcycled',
                vegan: 'Vegan',
                women_owned_business: 'Women-owned business',
                zero_waste: 'Zero waste',
            },
            statisticTimeframes: {
                '7': 'Past week',
                '30': 'Past 30 days',
                '90': 'Past 90 days',
                '180': 'Past 180 days',
                '365': 'Past 365 days',
            },
            statisticIntervals: {
                date: 'Daily',
                yearWeek: 'Weekly',
                yearMonth: 'Monthly',
            },
            countries: {
                al: 'Albania',
                ad: 'Andorra',
                am: 'Armenia',
                at: 'Austria',
                az: 'Azerbaijan',
                by: 'Belarus',
                be: 'Belgium',
                ba: 'Bosnia Herzegovina',
                bg: 'Bulgaria',
                hr: 'Croatia',
                cy: 'Cyprus',
                cz: 'Czech Republic',
                dk: 'Denmark',
                ee: 'Estonia',
                fi: 'Finland',
                fr: 'France',
                ge: 'Georgia',
                de: 'Germany',
                gr: 'Greece',
                hu: 'Hungary',
                is: 'Iceland',
                ie: 'Ireland',
                it: 'Italy',
                kz: 'Kazakhstan',
                xk: 'Kosovo',
                lv: 'Latvia',
                li: 'Liechtenstein',
                lt: 'Lithuania',
                lu: 'Luxembourg',
                mt: 'Malta',
                md: 'Moldova',
                me: 'Montenegro',
                nl: 'Netherlands',
                mk: 'North Macedonia',
                no: 'Norway',
                pl: 'Poland',
                pt: 'Portugal',
                ro: 'Romania',
                sm: 'San Marino',
                rs: 'Serbia',
                sk: 'Slovakia',
                si: 'Slovenia',
                es: 'Spain',
                se: 'Sweden',
                ch: 'Switzerland',
                tr: 'Turkey',
                ua: 'Ukraine',
                uk: 'United Kingdom',
                us: 'United States of America',
                va: 'Vatican City',
            },
            activeCountries: {
                al: 'Albania',
                ad: 'Andorra',
                at: 'Austria',
                by: 'Belarus',
                be: 'Belgium',
                ba: 'Bosnia Herzegovina',
                bg: 'Bulgaria',
                hr: 'Croatia',
                cy: 'Cyprus',
                cz: 'Czechia',
                dk: 'Denmark',
                ee: 'Estonia',
                fi: 'Finland',
                fr: 'France',
                ge: 'Georgia',
                de: 'Germany',
                gr: 'Greece',
                hu: 'Hungary',
                is: 'Iceland',
                ie: 'Ireland',
                it: 'Italy',
                xk: 'Kosovo',
                lv: 'Latvia',
                li: 'Liechtenstein',
                lt: 'Lithuania',
                lu: 'Luxembourg',
                mt: 'Malta',
                md: 'Moldova',
                me: 'Montenegro',
                nl: 'Netherlands',
                mk: 'North Macedonia',
                no: 'Norway',
                pl: 'Poland',
                pt: 'Portugal',
                ro: 'Romania',
                sm: 'San Marino',
                rs: 'Serbia',
                sk: 'Slovakia',
                si: 'Slovenia',
                es: 'Spain',
                se: 'Sweden',
                ch: 'Switzerland',
                tr: 'Turkey',
                ua: 'Ukraine',
                uk: 'United Kingdom',
                va: 'Vatican City',
            },
        },
    };
}
