import {Component, Vue} from 'vue-facing-decorator';
import {RouteLocationRaw} from 'vue-router';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Settings} from '@/app/Settings';
import {Store} from '@/app/stores/Store';
import FormLanguagePickerVue from '@/app/views/components/form/FormLanguagePicker.vue';
import FormSelectAvailabilityStatusPicker from '@/app/views/components/form/FormSelectAvailabilityStatusPicker';
import FormSelectAvailabilityStatusPickerVue from '@/app/views/components/form/FormSelectAvailabilityStatusPicker.vue';
import {FormSelectItem} from '@/app/views//components/form/FormSelect';
import {CompanyLayoutDTO} from '@/app/dto/CompanyLayoutDTO';

@Component({
    components: {
        FormLanguagePickerVue,
        'm-form-select-availability-status-picker': FormSelectAvailabilityStatusPickerVue,
    },
})
export default class Layout extends Vue {

    public flags = {
        loading: false,
        showAvatarMenu: false,
    };

    declare public $refs: {
        availabilityStatusSwitcher: FormSelectAvailabilityStatusPicker;
    };

    public get isBackoffice(): boolean {
        return Store.app.sideMenuVisible ||
            Routes.currentRoute.name === 'register' ||
            Routes.currentRoute.name === 'passwordRequest' ||
            Routes.currentRoute.name === 'registerCompany' ||
            Routes.currentRoute.name === 'buyerSubscriptionList' ||
            Routes.currentRoute.name === 'sellerSubscriptionList' ||
            Routes.currentRoute.name === 'companySubscriptionPending';
    }

    public get logoLinkTarget(): RouteLocationRaw {
        return (Store.user.user != null) ? {name: 'seoHome'} : Settings.urlMainSite;
    }

    public get supportsFlexboxGap(): boolean {
        // create flex container with row-gap set
        var flex = document.createElement('div');
        flex.style.position = 'absolute';
        flex.style.display = 'flex';
        flex.style.flexDirection = 'column';
        flex.style.rowGap = '1px';

        // create two, elements inside it
        flex.appendChild(document.createElement('div'));
        flex.appendChild(document.createElement('div'));

        // append to the DOM (needed to obtain scrollHeight)
        document.body.appendChild(flex);
        const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
        flex.parentNode.removeChild(flex);

        return isSupported;
    }

    public get showSignUpLink(): boolean {
        return Routes.currentRoute.name !== 'register' && Routes.currentRoute.name !== 'registerCompany';
    }

    public get showSignInLink(): boolean {
        return Routes.currentRoute.name !== 'seoHome';
    }

    public get availabilityStatuses(): FormSelectItem<string>[] {
        if (Store.user.isBuyer) {
            return Store.meta.buyerAvailabilityStatus;
        } else if (Store.user.isSeller) {
            return Store.meta.sellerAvailabilityStatus;
        }

        return [];
    }

    public created(): void {
        Store.company.reloadSettings();
    }

    public mounted(): void {
    }

    public register(): void {
        this.toggleFrontOfficeSideMenu(false);
        Main.app.openRegisterModal();
    }

    public login(): void {
        this.toggleFrontOfficeSideMenu(false);
        Main.app.openLoginModal();
    }

    public logout(): void {
        Store.user.logout();
    }

    public toggleSideMenu(open: boolean = null): void {
        Store.app.sideMenuOpened = (open !== null) ? open : (!Store.app.sideMenuOpened);
    }

    public toggleFrontOfficeSideMenu(open: boolean = null): void {
        Store.app.frontOfficeSideMenuOpened = (open !== null) ? open : (!Store.app.frontOfficeSideMenuOpened);
    }

    public openAvatarMenu(): void {
        this.flags.showAvatarMenu = true;
    }

    public closeAvatarMenu(): void {
        this.flags.showAvatarMenu = !this.flags.showAvatarMenu;
    }

    private handleResult(result: CompanyLayoutDTO): void {
        Store.company.reloadSettings();
    }
}
